import React, { useEffect, useRef } from "react"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"

import { useApi } from "@api"
import { IAppState, IPacksInfo } from "@store/appState"

import {
  receivedDailyPackStatusAction,
  receivedPacksAction,
  receivedStickersAction,
  receivedOpenPackStickersAction,
  receivedUnlockErrorAction,
  receivedOpenPackWaitAction,
  requestDailyPackStatusAction
} from "./actions"
import secondsLeft from "@helper/secondsLeft"
import { useTracking } from "@api/tracking"

const isError = (msg: Packs.Api.UnlockPackResponse): msg is Packs.Api.UnlockPackErrorResponse => {
  return !!(msg as Packs.Api.UnlockPackErrorResponse).error
}

export const usePacks = () => {
  const api = useApi<Packs.Api.Request, Packs.Api.Response>()
  const dispatch = useDispatch()
  const { track } = useTracking()

  const selected = useSelector<IAppState, {packs: IPacksInfo, initDone: boolean}>((state) => {
    return {
      packs: state.packs,
      initDone: state.initDone
    }
  })

  useEffect(() => { // repoll daily-packs-status when countdown over
    let interval: number

    if (!!selected.packs.receivedNextFreePackInfoAt && !_.isUndefined(selected.packs.nextFreePackInSeconds)) {
      interval = window.setInterval(() => {
        if (!selected.packs.hasNewPacksWaiting && secondsLeft(selected.packs.nextFreePackInSeconds as number, selected.packs.receivedNextFreePackInfoAt as Date) <= 0) {
          dispatch(requestDailyPackStatusAction())
        }
      }, 1000)
    }
    return () => { // cleanup
      window.clearInterval(interval)
    }
  }, [
    selected.packs.receivedNextFreePackInfoAt,
    selected.packs.nextFreePackInSeconds,
    selected.packs.hasNewPacksWaiting
  ])

  useEffect(() => {
    const listener = api.on((msg) => {
      if (msg.action === "daily_packs_status") {
        dispatch(receivedDailyPackStatusAction(msg))
      } else if (msg.action === "received_packs") {
        dispatch(receivedPacksAction(!selected.initDone, msg))
      } else if (msg.action === "received_stickers") {
        dispatch(receivedStickersAction(msg))
      } else if (msg.action === "unlock_pack") {
        if (isError(msg)) {
          dispatch(receivedUnlockErrorAction(msg))
        } else {
          track({
            eventType: "code__activate",
            pageName: "VirtualMagicCube",
            codeType: msg.is_multi_code ? "promo" : (msg.market || "pu")
          })
        }
      } else if (msg.action === "open_pack") {
        if (msg.stickers) {
          track({
            eventType: "pack__open",
            pageName: "Main"
          })
          dispatch(receivedOpenPackStickersAction(msg.stickers))
        } else if (msg.wait) {
          dispatch(receivedOpenPackWaitAction(msg.wait))
        }
      }
    })

    return () => { // cleanup
      api.off(listener)
    }
  }, [selected.initDone])
}
