import React, { useEffect, useState, useCallback, useRef } from "react"
import { useSelector } from "react-redux"
import I18n from "i18n-js"
import _ from "lodash"

import "./Stickers.scss"

import { IAppState } from "@store/appState"
import { HeaderFooterAndStrechedContent, OverlayedOnKioskOrFullscreenOnPhone } from "@ui/ViewContainer"
import CloseIcon from "@ui/CloseIcon"
import ViewTitle from "@ui/ViewTitle"
import { useHistory } from "react-router-dom"
import Sticker from "@ui/Sticker"
import { useStickerConfig } from "@context/StickerConfig"
import useDevice from "@helper/useDevice"
import useRefSize from "@helper/useRefSize"
import StickerPopup from "@ui/StickerPopup"
import sortStickersByAppearanceInAlbum from "@helper/sortStickersByAppearanceInAlbum"

const STICKER_SIZE_PHONE = 90
const STICKER_SIZE_TABLET = 140

export const StickersInner: React.FC<{stickers: number[], onClose(): void}> = ({ stickers, onClose }) => {
  const { getSticker, groups } = useStickerConfig()
  const { isPhone, isTablet } = useDevice()
  const contentRef = useRef<HTMLDivElement>()
  const { size: contentSize } = useRefSize(contentRef)
  const [ stickerForPopup, setStickerForPopup ] = useState<Base.Api.Sticker>()
  const sortedStickers = useCallback(() => {
    const mappedStickers = _.chain(stickers).map((sId) => getSticker(sId)).compact().value()
    return sortStickersByAppearanceInAlbum(mappedStickers, groups)
  }, [stickers.length])

  const contentInnerClassName = [
    "neo__stickers__content__inner",
    stickers.length > 0 ? "neo__stickers__content__inner--withStickers" : "neo__stickers__content__inner--noStickers"
  ].join(" ")

  return <OverlayedOnKioskOrFullscreenOnPhone withDarkBackdropAboveKiosk className="neo__stickers">
    {stickerForPopup && <StickerPopup sticker={stickerForPopup} closePopup={() => setStickerForPopup(undefined)} />}
    <CloseIcon onClick={onClose} positionTopRight />
    <HeaderFooterAndStrechedContent
      contentClassName="neo__stickers__content"
      ref={contentRef}
      header={<ViewTitle type="onDark">{I18n.t("stack.type.move.title")} ({stickers.length})</ViewTitle>}>
      <div className={contentInnerClassName} style={contentSize ? { maxHeight: `${Math.floor(contentSize.height)}px`} : undefined}>
        {stickers.length > 0
          ? _.map(sortedStickers(), (sticker, index) => {
              return <Sticker
                sticker={sticker}
                onClick={() => setStickerForPopup(sticker)}
                key={index}
                rotation="correctSideUp" size={isPhone ? STICKER_SIZE_PHONE : STICKER_SIZE_TABLET} />
            })
          : <div className="neo__stickers__noStickersInfo">{I18n.t("teaser.no_stickers_open_some_packs")}</div>}
      </div>
    </HeaderFooterAndStrechedContent>
  </OverlayedOnKioskOrFullscreenOnPhone>
}

const Stickers: React.FC = () => {
  const history = useHistory()
  const { stickers } = useSelector<IAppState, {stickers: number[]}>((s) => { return { stickers: s.tempStack }})

  return <StickersInner stickers={stickers} onClose={() => history.push("/")}/>
}

export default Stickers
