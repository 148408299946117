import { IAppState } from "@store/appState"
import { useSelector } from "react-redux"

declare global {
  interface Window {
    _satellite?: {
      track: (identifier: EventType, data: TrackingData) => void
    }
  }
}

export type BaseTrackingData = Record<string, any> // Base Data + SSO Data

interface PackOpenTrackingData {
  eventType: "pack__open"
  pageName: "Main"
}

interface CodeActivateTrackingData {
  eventType: "code__activate"
  pageName: "VirtualMagicCube"
  codeType: "promo" | "pu" | string
}

export type TrackingData = BaseTrackingData & SpecialTrackingData

type EventType = TrackingData["eventType"]

type SpecialTrackingData = CodeActivateTrackingData | PackOpenTrackingData

const safeTrack = (eventType: EventType, data: TrackingData) => {
  try {
    if (window._satellite) {
      window._satellite.track(eventType, data)
    } else {
      console.warn("_satellite is missing")
    }
  } catch (e) {
    console.error(e)
  }
}

export const useTracking = () => {
  const baseData = useSelector((state: IAppState) => state.trackingData)

  return {
    track: (specData: SpecialTrackingData) => {
      const data = {
        ...baseData,
        ...specData
      }
      console.log(`track ${data.eventType}`, data)
      safeTrack(data.eventType, data)
    }
  }
}