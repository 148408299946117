import React, { useMemo } from "react"
import { applyMiddleware, compose, createStore, combineReducers, Action, Reducer } from "redux"
import thunkMiddleware, { ThunkAction } from "redux-thunk"
import _ from "lodash"
import { Provider as ReduxProvider } from "react-redux"

import { IApi, useApi } from "@api"
import appReducers from "./appReducers"
import { IAppState, initialState } from "./appState"
import { BaseTrackingData } from "@api/tracking"

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  IAppState,
  IApi<Base.Api.AllRequests, Base.Api.AllResponses>,
  Action<string>
>

const reducers: Reducer<IAppState> = (state, action) => {
  let newState = state || initialState
  _.each(appReducers, (r) => newState = r(newState, action as any))
  return newState
}

export function configureStore(api: IApi<Base.Api.AllRequests, Base.Api.AllResponses>, initialState: IAppState, forSandbox?: boolean) {
  const composedEnhancers = ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(
    applyMiddleware(thunkMiddleware.withExtraArgument(api))
  )

  const store = createStore(forSandbox ? ((s: any) => s) : reducers, initialState, composedEnhancers as any)

  return store
}

export const WithStore: React.FC<{forSandbox?: boolean, connectError?: string, trackingData: BaseTrackingData}> = ({ children, forSandbox, connectError, trackingData }) => {
  const api = useApi<Base.Api.AllRequests, Base.Api.AllResponses>()
  const store = useMemo(() => configureStore(api, { ...initialState, connectError, trackingData }, forSandbox), [])

  return <ReduxProvider store={store}>
    { children }
  </ReduxProvider>
}
