import _ from "lodash"

const compNum = (num1: number, num2: number) => {
  if (num1 === num2) { return 0 }
  if (num1 < num2) { return -1 }
  return 1
}

const groupIndex = (groupsInAlbumOrder: Base.Api.Group[], groupUid: string) => {
  return _.findIndex(groupsInAlbumOrder, (g) => g.uid === groupUid)
}


const sortStickersByAppearanceInAlbum = (stickers: Base.Api.Sticker[], groupsInAlbumOrder: Base.Api.Group[]) => {
  return _.sortBy(stickers, ["sort_id", (s) => s.info.golden ? 0 : 1, "id"])
}

export default sortStickersByAppearanceInAlbum
