import React, { useCallback, useEffect, useMemo } from "react"
import { useApi, WithApi } from "./api"
import _ from "lodash"
import { WithStore } from "./store"
import { useDispatch, useSelector } from "react-redux"
import { requestInitAction, useInit } from "@store/init"
import { useAllFeatures } from "@store/appReducers"
import LoadConfigs from "@context/LoadConfigs"
import { CompleteAccountContext } from "@context/CompleteAccountContext"
import Kiosk from "@ui/Kiosk"
import FooterWithAppLinks from "@ui/FooterWithAppLinks"
import { HashRouter } from "react-router-dom"
import AppRoutes from "./AppRoutes"
import { requestDailyPackStatusAction } from "@store/packs/actions"
import { CenteredInKioskView } from "@ui/ViewContainer"
import { WithAppLinks } from "@context/AppLinksContext"
import { IAppState } from "@store/appState"
import { BaseTrackingData } from "@api/tracking"
import OnlyEnterCode, { KioskWrapperWhenOnlyEnterCode, WrapperWhileLoadingConfigsOnlyEnterCode } from "@view/OnlyEnterCode"

export interface AppProps {
  ios_url: string
  android_url: string
  api_host: string
  locale: string
  asset_host: string
  connect_error?: string
  tracking_data: BaseTrackingData
  onlyEnterCode?: boolean
}

const CompleteApp: React.FC = () => {
  return <HashRouter>
    <AppRoutes />
  </HashRouter>
}

const AppHolderThatMountsAllFeatures: React.FC = ({children}) => {
  const dispatch = useDispatch()

  const initDone = useSelector<IAppState, boolean>((s) => s.initDone)

  useAllFeatures()

  useEffect(() => { // send initial requests to server
    dispatch(requestInitAction())
    dispatch(requestDailyPackStatusAction())
  }, [])

  return <div className="neo__app">
    {initDone && children}
  </div>
}

const WrapperWhileLoadingConfigs: React.FC = ({ children }) => {
  return <Kiosk whileLoading><CenteredInKioskView>{children}</CenteredInKioskView></Kiosk>
}

const startConnectAccount = () => {
  (window as any).APP.require("gigya").triggerRegistration()
}

const App: React.FC<AppProps> = ({ios_url, android_url, api_host, asset_host, locale, connect_error, tracking_data, onlyEnterCode}) => {
  return <div className="neo__teaserExperience">
      <LoadConfigs assetHost={asset_host} locale={locale} WrapperWhileLoading={onlyEnterCode ? WrapperWhileLoadingConfigsOnlyEnterCode : WrapperWhileLoadingConfigs}>
        <CompleteAccountContext.Provider value={{completeAccount: startConnectAccount}}>
          <WithApi apiHost={api_host}>
            <WithAppLinks ios={ios_url} android={android_url}>
              <WithStore connectError={connect_error} trackingData={tracking_data}>
                {onlyEnterCode
                  ? <>
                      <KioskWrapperWhenOnlyEnterCode>
                        <AppHolderThatMountsAllFeatures>
                          <OnlyEnterCode />
                        </AppHolderThatMountsAllFeatures>
                      </KioskWrapperWhenOnlyEnterCode>
                    </>
                  : <>
                      <Kiosk>
                        <AppHolderThatMountsAllFeatures>
                          <CompleteApp />
                        </AppHolderThatMountsAllFeatures>
                      </Kiosk>
                    </>}
                <FooterWithAppLinks />
              </WithStore>
            </WithAppLinks>
          </WithApi>
        </CompleteAccountContext.Provider>
      </LoadConfigs>
  </div>
}

export default App
