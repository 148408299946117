import { IAppState } from "@store/appState"
import * as Actions from "./actions"

export const packsReducer = (
  state: IAppState,
  action: Actions.ActionTypes
): IAppState => {
  switch (action.type) {
    case Actions.DAILY_PACKS_STATUS_REQUEST:
    case Actions.REQUEST_RECEIVE_DAILY_PACKS:
    case Actions.REQUEST_OPEN_PACK:
      return state
    case Actions.DAILY_PACKS_STATUS_RECEIVED:
      return {
        ...state,
        packs: {
          ...state.packs,
          hasNewPacksWaiting: action.daily_packs_status.has_new_packs_waiting,
          nextFreePackInSeconds: action.daily_packs_status.new_packs_in_sec,
          receivedNextFreePackInfoAt: new Date()
        }
      }
    case Actions.RECEIVED_PACKS_RECEIVED:
      return {
        ...state,
        packs: {
          ...state.packs,
          numUnopened: action.received_packs.total_packs
        },
        ...(action.areInitialPacks ? {} : { showReceivedPacks: {amount: action.received_packs.amount }}),
        ...((action.received_packs.from_entered_code)
          ? {
              codeUnlocking: {
                ...state.codeUnlocking,
                codeValid: true
              }
            }
          : {}
        )
      }
    case Actions.RECEIVED_STICKERS_RECEIVED:
      return {
        ...state,
        tempStack: [
          ...state.tempStack,
          ...action.received_stickers.stickers
        ],
        showPackOpening: {
          receivedStickers: action.received_stickers.stickers
        },
        codeUnlocking: {
          ...state.codeUnlocking,
          codeValid: true
        }
      }
    case Actions.RECEIVED_OPEN_PACK_STICKERS:
      return {
        ...state,
        showPackOpening: {
          receivedStickers: action.stickers
        },
        packs: {
          ...state.packs,
          numUnopened: Math.max(state.packs.numUnopened - 1, 0)
        },
        tempStack: [ ...state.tempStack, ...action.stickers ]
      }
    case Actions.RECEIVED_OPEN_PACK_WAIT:
      return {
        ...state,
        showPackOpening: {
          wait: action.wait
        }
      }
    case Actions.SHOW_PACK_OPENING_DONE:
      return {
        ...state,
        showPackOpening: undefined
      }
    case Actions.SHOW_RECEIVED_PACKS_DONE:
      return {
        ...state,
        showReceivedPacks: undefined
      }
    case Actions.REQUEST_UNLOCK_PACK:
      return {
        ...state,
        codeUnlocking: {
          enteredCode: action.code
        }
      }
    case Actions.RECEIVED_UNLOCK_ERROR:
      return {
        ...state,
        codeUnlocking: {
          enteredCode: state?.codeUnlocking?.enteredCode,
          error: action.error.error
        }
      }
    case Actions.RESET_CODE_UNLOCKING:
      return {
        ...state,
        codeUnlocking: undefined
      }
  }
  return state
}
