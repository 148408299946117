import React, { useState } from "react"

import DownloadAppPopup from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"
import { WithAppLinks } from "@context/AppLinksContext"

const Sandbox: React.FC = () => {
  const [ show, setShow ] = useState(false)

  return <>
    <SectionTitle>
      DownloadAppPopup <a onClick={() => setShow(true)}>SHOW!</a>
    </SectionTitle>
    <WithAppLinks ios={"/ios"} android={"/android"}>
      <Kiosk>
        {show && <DownloadAppPopup closePopup={() => setShow(false)} />}
      </Kiosk>
    </WithAppLinks>
  </>
}

export default ["Popups", Sandbox]
