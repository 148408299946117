import React from "react"

import "./Shine.scss"

const Shine: React.FC<{className?: string, delayMillis: number, durationMillis: number}> = ({ delayMillis, durationMillis, className }) => {
  return <div
    className={`neo__shine ${className ? className : ""}`}
    style={{
      animationDuration: `${durationMillis}ms`,
      animationDelay: `${delayMillis}ms`
    }} />
}

export default Shine
