import React, { useState } from "react"
import _ from "lodash"

import Pack from "./"

import { PageTitle, SectionTitle, Note, Block, BlockTitle } from "@sandbox/Sandbox"

const Sandbox: React.FC = () => {
  const [ firstIsOpen, setFirstIsOpen ] = useState(false)
  const [ shineKey, setShineKey ] = useState(0)

  return <>
    <PageTitle>Sticker</PageTitle>

    <Note>
      <a onClick={() => setFirstIsOpen(!firstIsOpen)}>TOGGLE OPEN!</a>
    </Note>
    <BlockTitle>Initially unopened</BlockTitle>
    <Block centered>
      <Pack open={firstIsOpen} size={120} />
    </Block>
    <BlockTitle>Initially open</BlockTitle>
    <Block centered>
      <Pack open={!firstIsOpen} size={120} />
    </Block>
    <BlockTitle>With shine <a onClick={() => setShineKey((old) => old + 1)}>Shine again</a></BlockTitle>
    <Block centered darkBg>
      <Pack key={shineKey} size={120} shine={{durationMillis: 2000, delayMillis: 300}} />
    </Block>
  </>
}

export default Sandbox
