const twoDigits = (num: number) => num < 10 ? `0${num}` : num

const prettyCountdownSeconds = (seconds: number) => {
  const h = Math.floor(seconds / (60 * 60))
  const min = Math.floor((seconds - h * 60 * 60) / 60)
  const sec = seconds - h * 60 * 60 - min * 60
  return `${h}:${twoDigits(min)}:${twoDigits(sec)}`
}

export default prettyCountdownSeconds
