import React, { useEffect } from "react"

function useOnClickOutside(ref: React.RefObject<any>, fn: () => void) {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      fn()
    }
    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => { // cleanup
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, fn])
}

export default useOnClickOutside
