import React, { useState } from "react"
import _ from "lodash"

import { StickersInner } from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"
import { useStickerConfig } from "@context/StickerConfig"

const Sandbox: React.FC = () => {
  const { stickers } = useStickerConfig()
  const [ showScenario, setShowScenario ] = useState<number>()

  return <>
    <PageTitle>Stickers</PageTitle>

    <SectionTitle>No stickers (show info) <a onClick={() => setShowScenario(1)}>SHOW!</a></SectionTitle>
    <Block>
      <Kiosk>
        {showScenario === 1 && <StickersInner stickers={[]} onClose={() => setShowScenario(undefined)}/>}
      </Kiosk>
    </Block>

    <SectionTitle>Few stickers (no scroll) <a onClick={() => setShowScenario(2)}>SHOW!</a></SectionTitle>
    <Block>
      <Kiosk>
        {showScenario === 2 && <StickersInner stickers={_.chain(stickers).sampleSize(4).map((s) => s.id).value()} onClose={() => setShowScenario(undefined)}/>}
      </Kiosk>
    </Block>

    <SectionTitle>Many stickers (scrollable) <a onClick={() => setShowScenario(3)}>SHOW!</a></SectionTitle>
    <Block>
      <Kiosk>
        {showScenario === 3 && <StickersInner stickers={_.map(stickers, (s) => s.id)} onClose={() => setShowScenario(undefined)}/>}
      </Kiosk>
    </Block>
  </>
}

export default Sandbox
