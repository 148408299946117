import React from "react"

import "./Kiosk.scss"

const Kiosk: React.FC<{whileLoading?: boolean}> = ({ children, whileLoading }) => {
  return (
    <div className={`neo__kiosk ${whileLoading ? "neo__kiosk--whileLoading" : ""}`}>
      <div className="neo__kiosk__outerShadowHolder">
        <div className="neo__kiosk__inner">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Kiosk
