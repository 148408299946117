import React, { createContext, useContext, useMemo } from "react"
import _ from "lodash"
import { useAssetPath } from "./AssetPath"

interface IStickerConfigContext {
  getSticker(stickerId: number): Base.Api.Sticker | undefined
  getGroup(groupUid: string): Base.Api.Group | undefined
  stickers: Base.Api.Sticker[]
  groups: Base.Api.Group[]
  getStickerImage(sticker: number | Base.Api.Sticker, golden?: boolean): string
}

const StickerConfigContext = createContext<IStickerConfigContext>({
  getSticker: () => undefined,
  getGroup: () => undefined,
  stickers: [],
  groups: [],
  getStickerImage: () => ""
})
StickerConfigContext.displayName = "StickerConfigContext"

interface WithStickerConfigProps {
  stickers: Base.Api.Sticker[]
  groups: Base.Api.Group[]
}

export const WithStickerConfig: React.FC<WithStickerConfigProps> = ({ stickers, groups, children }) => {
  const { getAssetPath } = useAssetPath()

  const stickersById: {[id: number]: Base.Api.Sticker} = useMemo(() => {
    const stickersById: {[id: number]: Base.Api.Sticker} = {}
    _.each(stickers, (st) => stickersById[st.id] = st)
    return stickersById
  }, [])

  const groupsByUid: {[uid: string]: Base.Api.Group} = useMemo(() => {
    const groupsByUid: {[uid: string]: Base.Api.Group} = {}
    _.each(groups, (g) => groupsByUid[g.uid] = g)
    return groupsByUid
  }, [])

  const getSticker = (stickerId: number) => {
    return stickersById[stickerId] || undefined
  }

  const getGroup = (groupUid: string) => {
    return groupsByUid[groupUid] || undefined
  }

  const getStickerImage = (sticker: number | Base.Api.Sticker, golden?: boolean) => {
    const id = __isStickerConfig(sticker) ? sticker.id : sticker
    return getAssetPath(`images/stickers/large/${id}.jpg`)
  }

  return <StickerConfigContext.Provider value={{ getSticker, getGroup, stickers, groups, getStickerImage }}>
    {children}
  </StickerConfigContext.Provider>
}

const __isStickerConfig = (val: any): val is Base.Api.Sticker => { return !_.isNumber(val) }

export const useStickerConfig = () => useContext(StickerConfigContext)
