import React, { useState } from "react"
import _ from "lodash"

import { CenteredInKioskView, CenteredPopup, OverlayedOnKioskOrFullscreenOnPhone } from "./"

import { PageTitle, SectionTitle, Block, BlockTitle, Note } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"

const scenarios = [
  ["Just centered", ({ children }: any) => <CenteredInKioskView>{children}</CenteredInKioskView>],
  ["Overlay", ({ children }: any) => <OverlayedOnKioskOrFullscreenOnPhone>{children}</OverlayedOnKioskOrFullscreenOnPhone>],
  ["Overlay + centered Child", ({ children }: any) => <OverlayedOnKioskOrFullscreenOnPhone centerChild>{children}</OverlayedOnKioskOrFullscreenOnPhone>],
  ["Overlay + centered Child + dark backdrop", ({ children }: any) => <OverlayedOnKioskOrFullscreenOnPhone centerChild withDarkBackdropAboveKiosk>{children}</OverlayedOnKioskOrFullscreenOnPhone>]
]

const Sandbox: React.FC = () => {
  const [ scenarioIndex, setScenarioIndex ] = useState(0)

  const Comp = scenarios[scenarioIndex][1]
  return <>
    <PageTitle>ViewContainer</PageTitle>
    <Note>
      Check the different position helpers for content above the Kiosk. On Phone, fullscreen content should always have a dark backdrop.
    </Note>
    <Block>
      <Kiosk>
        <Comp>
          <ul style={{padding: "1em", background: "white"}}>
            {_.map(scenarios, (s, index) => {
              return <li key={index}>
                <a style={{fontWeight: index === scenarioIndex ? "bold" : "normal"}}onClick={() => setScenarioIndex(index)}>{s[0]}</a>
              </li>
            })}
          </ul>
        </Comp>
      </Kiosk>
    </Block>
    <BlockTitle>CenteredPopup</BlockTitle>
    <Block>
      <Kiosk>
        <a onClick={() => alert("oh no!")}>This should not be clickable (overlayed by popup)!</a>
        <CenteredPopup><div style={{padding: ".25em 1em", background: "white", borderRadius: "1em"}}>CenteredPopup</div></CenteredPopup>
      </Kiosk>
    </Block>
  </>
}

export default Sandbox
