import React from "react"
import ReactDOM from "react-dom"

import "@styles/base.scss"

import App, { AppProps } from "./App"
import Sandbox from "@sandbox/Sandbox"

document.addEventListener("DOMContentLoaded", () => {
  const appTarget = document.getElementById("neoJs__teaserExperience")
  if (appTarget) {
    const config = appTarget.hasAttribute("data-config")
      ? JSON.parse(appTarget.getAttribute("data-config") as string) as AppProps
      : undefined

    if (!config) { return }

    ReactDOM.render(
      <App {...config} onlyEnterCode />,
      appTarget
    )
  }

  const sandboxTarget = document.getElementById("neoJs__teaserSandbox")
  if (sandboxTarget) {
    const config = sandboxTarget.hasAttribute("data-config")
      ? JSON.parse(sandboxTarget.getAttribute("data-config") as string) as AppProps
      : undefined

    if (!config) { return }
    ReactDOM.render(
      <Sandbox {...config} />,
      sandboxTarget
    )
  }
})
