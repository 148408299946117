import CloseIcon from "@ui/CloseIcon"
import { IconInfo } from "@ui/Icon"
import { CenteredPopup } from "@ui/ViewContainer"
import React, { useRef } from "react"
import I18n from "i18n-js"
import Button from "@ui/Button"
import useOnClickOutside from "@helper/useOnClickOutside"

import "./NoMorePacksPopup.scss"

export const NoMorePacksPopup: React.FC<{closePopup(): void}> = ({ closePopup }) => {
  const domRef = useRef<HTMLDivElement>()

  useOnClickOutside(domRef, closePopup)

  return <CenteredPopup>
    <div ref={(ref: any) => { if (ref) { domRef.current = ref }}} className="neo__noMorePacks">
      <div className="neo__noMorePacks__inner">
        <CloseIcon className="neo__noMorePacks__closeIcon" onClick={() => closePopup()} />
        <div className="neo__noMorePacks__panel">
          <h3>{I18n.t("daily_packs.get_more_packs")}</h3>
          <p>
            <Button type="primaryOnLight" onClick={closePopup}>{I18n.t("general.ok")}</Button>
          </p>
        </div>
      </div>
    </div>
  </CenteredPopup>
}

export default NoMorePacksPopup
