import React, { useState } from "react"

import ConnectErrorPopup from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"

const Sandbox: React.FC = () => {
  const [ show, setShow ] = useState(false)

  return <>
    <SectionTitle>
      ConnectErrorPopup <a onClick={() => setShow(true)}>SHOW!</a>
    </SectionTitle>
    <Kiosk>
      {show && <ConnectErrorPopup msg={`This is a <strong>Connet Error</strong>!`} closePopup={() => setShow(false)} />}
    </Kiosk>
  </>
}

export default ["Popups", Sandbox]
