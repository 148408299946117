import { AppThunk } from "@store"

/* Action Types */

export const DAILY_PACKS_STATUS_REQUEST = "DAILY_PACKS_STATUS_REQUEST"
export const DAILY_PACKS_STATUS_RECEIVED = "DAILY_PACKS_STATUS_RECEIVED"
export const RECEIVED_PACKS_RECEIVED = "RECEIVED_PACKS_RECEIVED"
export const RECEIVED_STICKERS_RECEIVED = "RECEIVED_STICKERS_RECEIVED"
export const REQUEST_OPEN_PACK = "REQUEST_OPEN_PACK"
export const RECEIVED_OPEN_PACK_STICKERS = "RECEIVED_OPEN_PACK_STICKERS"
export const RECEIVED_OPEN_PACK_WAIT = "RECEIVED_OPEN_PACK_WAIT"
export const SHOW_PACK_OPENING_DONE = "SHOW_PACK_OPENING_DONE"
export const SHOW_RECEIVED_PACKS_DONE = "SHOW_RECEIVED_PACKS_DONE"
export const REQUEST_UNLOCK_PACK = "REQUEST_UNLOCK_PACK"
export const REQUEST_RECEIVE_DAILY_PACKS = "REQUEST_RECEIVE_DAILY_PACKS"
export const RECEIVED_UNLOCK_ERROR = "RECEIVED_UNLOCK_ERROR"
export const RESET_CODE_UNLOCKING = "RESET_CODE_UNLOCKING"


interface RequestDailyPacksStatusAction {
  type: typeof DAILY_PACKS_STATUS_REQUEST
}
interface RequestReceiveDailyPacks {
  type: typeof REQUEST_RECEIVE_DAILY_PACKS
}
interface ShowReceivedPacksDoneAction {
  type: typeof SHOW_RECEIVED_PACKS_DONE
}
interface ReceivedDailyPacksStatusAction {
  type: typeof DAILY_PACKS_STATUS_RECEIVED
  daily_packs_status: Packs.Api.DailyPacksStatusResponse
}
interface ReceivedPacksAction {
  type: typeof RECEIVED_PACKS_RECEIVED
  received_packs: Packs.Api.ReceivedPacksResponse
  areInitialPacks: boolean
}
interface ReceivedStickersAction {
  type: typeof RECEIVED_STICKERS_RECEIVED
  received_stickers: Packs.Api.ReceivedStickersResponse
}
interface RequestOpenPackAction {
  type: typeof REQUEST_OPEN_PACK
  open_pack: Packs.Api.OpenPackRequest
}
interface ReceivedOpenPackStickersAction {
  type: typeof RECEIVED_OPEN_PACK_STICKERS
  stickers: number[]
}
interface ReceivedOpenPackWaitAction {
  type: typeof RECEIVED_OPEN_PACK_WAIT
  wait: Packs.Api.OpenPackWait
}
interface ShowPackOpeningDoneAction {
  type: typeof SHOW_PACK_OPENING_DONE
}
interface RequestUnlockPackAction {
  type: typeof REQUEST_UNLOCK_PACK
  code: string
}
interface ReceivedUnlockErrorAction {
  type: typeof RECEIVED_UNLOCK_ERROR
  error: Packs.Api.UnlockPackErrorResponse
}
interface ResetCodeUnlockingAction {
  type: typeof RESET_CODE_UNLOCKING
}


export type ActionTypes = RequestDailyPacksStatusAction
  | RequestReceiveDailyPacks
  | ShowReceivedPacksDoneAction
  | ReceivedDailyPacksStatusAction
  | ReceivedPacksAction
  | ReceivedStickersAction
  | RequestOpenPackAction
  | ReceivedOpenPackStickersAction
  | ReceivedOpenPackWaitAction
  | ShowPackOpeningDoneAction
  | RequestUnlockPackAction
  | ReceivedUnlockErrorAction
  | ResetCodeUnlockingAction

/* Action Creators */

export const requestDailyPackStatusAction = (): AppThunk => (dispatch, _getState, api) => {
  dispatch({ type: DAILY_PACKS_STATUS_REQUEST })
  api.send({ action: "daily_packs_status" })
}

export const requestOpenPackAction = (): AppThunk => (dispatch, _getState, api) => {
  dispatch({ type: REQUEST_OPEN_PACK })
  api.send({ action: "open_pack" })
}

export const requestUnlockPackAction = (code: string): AppThunk => (dispatch, _getState, api) => {
  dispatch({ type: REQUEST_UNLOCK_PACK, code })
  api.send({ action: "unlock_pack", code })
}

export const requestReceiveDailyPacksAction = (): AppThunk => (dispatch, _getState, api) => {
  dispatch({ type: REQUEST_RECEIVE_DAILY_PACKS })
  api.send({ action: "receive_daily_packs" })
}

export const receivedUnlockErrorAction = (
  error: Packs.Api.UnlockPackErrorResponse
): ReceivedUnlockErrorAction => {
  return {
    type: RECEIVED_UNLOCK_ERROR,
    error
  }
}

export const receivedDailyPackStatusAction = (
  daily_packs_status: Packs.Api.DailyPacksStatusResponse
): ReceivedDailyPacksStatusAction => {
  return {
    type: DAILY_PACKS_STATUS_RECEIVED,
    daily_packs_status
  }
}

export const receivedPacksAction = (
  areInitialPacks: boolean,
  received_packs: Packs.Api.ReceivedPacksResponse
): ReceivedPacksAction => {
  return {
    type: RECEIVED_PACKS_RECEIVED,
    areInitialPacks,
    received_packs
  }
}

export const receivedStickersAction = (
  received_stickers: Packs.Api.ReceivedStickersResponse
): ReceivedStickersAction => {
  return {
    type: RECEIVED_STICKERS_RECEIVED,
    received_stickers
  }
}

export const showPackOpeningDoneAction = (): ShowPackOpeningDoneAction => {
  return {
    type: SHOW_PACK_OPENING_DONE
  }
}

export const showReceivedPacksDoneAction = (): ShowReceivedPacksDoneAction => {
  return {
    type: SHOW_RECEIVED_PACKS_DONE
  }
}

export const resetCodeUnlockingAction = (): ResetCodeUnlockingAction => {
  return {
    type: RESET_CODE_UNLOCKING
  }
}

export const receivedOpenPackStickersAction = (
  stickers: number[]
): ReceivedOpenPackStickersAction => {
  return {
    type: RECEIVED_OPEN_PACK_STICKERS,
    stickers
  }
}

export const receivedOpenPackWaitAction = (
  wait: Packs.Api.OpenPackWait
): ReceivedOpenPackWaitAction => {
  return {
    type: RECEIVED_OPEN_PACK_WAIT,
    wait
  }
}
