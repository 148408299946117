import { IconLocked } from "@ui/Icon"
import React from "react"
import I18n from "i18n-js"

import "./BottomBar.scss"

const BottomBar: React.FC<{onClick(): void}> = ({ onClick }) => {
  return <div className="neo__bottomBar__holder">
    <div className="neo__bottomBar" onClick={() => onClick()}>
      <div className="neo__lockPositioner"><IconLocked /></div>
      <div className="neo__bottomBar__listHolder">
        <div>{I18n.t("welcome.btn_challenges")}</div>
        <div>{I18n.t("welcome.btn_swap")}</div>
        <div>{I18n.t("collectors_team.hub.title")}</div>
        <div>{I18n.t("welcome.btn_more")}</div>
      </div>
    </div>
  </div>
}

export default BottomBar
