import React from "react"

import FooterWithAppLinks from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"
import { WithAppLinks } from "@context/AppLinksContext"

const Sandbox: React.FC = () => {
  return <>
    <PageTitle>FooterWithAppLinks</PageTitle>

    <BlockTitle>Both links</BlockTitle>
    <Block>
      <WithAppLinks ios={"/ios"} android={"/android"}>
        <FooterWithAppLinks />
      </WithAppLinks>
    </Block>

    <BlockTitle>No links available</BlockTitle>
    <Block>
      <WithAppLinks ios={undefined} android={undefined}>
        <FooterWithAppLinks />
      </WithAppLinks>
    </Block>
  </>
}

export default Sandbox
