import React, { useEffect, useState } from "react"
import _ from "lodash"
import useOnWindowResize from "./useOnWindowResize"

const useRefSize = (ref: React.MutableRefObject<HTMLElement | undefined>) => {
  const [ recalcToken, setRecalcToken ] = useState(0)

  const updateSize = () => {
    if (ref.current) {
      const bounds = ref.current.getBoundingClientRect()
      setSize({width: bounds.width, height: bounds.height})
    }
  }

  const [ size, setSize ] = useState<{width: number, height: number}>()
  useOnWindowResize(updateSize)

  useEffect(() => {
    updateSize()

    const interval = window.setInterval(updateSize, 300)

    return () => { // cleanup
      window.clearInterval(interval)
    }
  }, []) // initially

  useEffect(() => {
    if (recalcToken > 0 ) { updateSize() }
  }, [recalcToken]) // initially

  return { size, recalcSize: () => setRecalcToken((old) => old + 1) }
}

export default useRefSize
