import React, { useState } from "react"
import _ from "lodash"

import { OpenPackInner } from "./"

import { PageTitle, SectionTitle, Block, BlockTitle, Note, Scenarios, IScenario } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"
import { useStickerConfig } from "@context/StickerConfig"

const Sandbox: React.FC = () => {
  const { stickers } = useStickerConfig()
  const someStickers = (amount: number) => _.chain(stickers).sampleSize(amount).map((s) => s.id).value()

  const scenarios: IScenario[] = [
    {
      title: "Loading",
      note: "Use this scenario always before one of the others",
      highlightNote: true,
      needsRemount: true,
      el: (setScenarioIndex) => {
        return <OpenPackInner state={{
          type: "loading"
        }} onClose={() => setScenarioIndex(undefined)} />
      }
    },
    {
      title: "Error",
      el: (setScenarioIndex) => {
        return <OpenPackInner state={{
          type: "cantOpen",
          isGuest: false,
          wait: {
            reason: "You can't open any more packs, <strong>try again in:</strong>",
            countdown_seconds: 9999
          }
        }} onClose={() => setScenarioIndex(undefined)} />
      }
    },
    {
      title: "Error + isGuest",
      note: "should have button to complete profile in it",
      el: (setScenarioIndex) => {
        return <OpenPackInner state={{
          type: "cantOpen",
          isGuest: true,
          wait: {
            reason: "You can't open any more packs, <strong>as a guest you can only open one pack a day</strong>. Try again in:",
            countdown_seconds: 9999
          }
        }} onClose={() => setScenarioIndex(undefined)} />
      }
    },
    {
      title: "Get 1 sticker",
      el: (setScenarioIndex) => {
        return <OpenPackInner state={{
          type: "opened",
          stickers: someStickers(1)
        }} onClose={() => setScenarioIndex(undefined)} />
      }
    },
    {
      title: "Get 5 stickers",
      note: "This is the default case for all users",
      el: (setScenarioIndex) => {
        return <OpenPackInner state={{
          type: "opened",
          stickers: someStickers(5)
        }} onClose={() => setScenarioIndex(undefined)} />
      }
    },
    {
      title: "Get 10 stickers",
      el: (setScenarioIndex) => {
        return <OpenPackInner state={{
          type: "opened",
          stickers: someStickers(10)
        }} onClose={() => setScenarioIndex(undefined)} />
      }
    },
    {
      title: "Get 30 stickers",
      note: "(does not happen for real, just to check that placement works)",
      el: (setScenarioIndex) => {
        return <OpenPackInner state={{
          type: "opened",
          stickers: someStickers(30)
        }} onClose={() => setScenarioIndex(undefined)} />
      }
    }
  ]


  return <>
    <PageTitle>Open Pack</PageTitle>

    <Scenarios scenarios={scenarios} WrapWith={({children}) => <Kiosk>{children}</Kiosk>}/>
  </>
}

export default Sandbox
