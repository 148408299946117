import React from "react"

import ViewTitle from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"

const Sandbox: React.FC = () => {
  return <>
    <SectionTitle>ViewTitle</SectionTitle>
    <Block>
      <ViewTitle type="onLight">Open a Pack!</ViewTitle>
    </Block>
    <Block darkBg>
      <ViewTitle type="onDark">Open a Pack!</ViewTitle>
    </Block>
  </>
}

export default ["Ui", Sandbox]
