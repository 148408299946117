import KioskItems from "@view/KioskItems"
import OpenPack from "@view/OpenPack"
import EnterCode from "@view/EnterCode"
import React from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group"
import Stickers from "@view/Stickers"

const DEFAULT_TIMEOUT = {enter: 500, exit: 300}
const OVERRIDE_DEFAULT_TIMEOUT: {[pathname: string]: any} = {
  "/open_pack": {enter: 1000, exit: 500}
}

const AppRoutes: React.FC = () => {
  const location = useLocation()
  const timeout = OVERRIDE_DEFAULT_TIMEOUT[location.pathname] || DEFAULT_TIMEOUT

  return (
    <SwitchTransition>
      <CSSTransition key={location.pathname} classNames="viewFade" timeout={timeout}>
       <Switch location={location}>
         <Route path="/enter_code" component={EnterCode} />
         <Route path="/open_pack" component={OpenPack} />
         <Route path="/stickers" component={Stickers} />
         <Route path="/" component={KioskItems} />
       </Switch>
      </CSSTransition>
    </SwitchTransition>
  )
}

export default AppRoutes
