import React, { useEffect, useState, useCallback, ChangeEvent, KeyboardEvent, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import I18n from "i18n-js"
import _ from "lodash"

import "./EnterCode.scss"

import getErrorMessage from "@helper/getErrorMessage"
import { requestUnlockPackAction, resetCodeUnlockingAction, showPackOpeningDoneAction, showReceivedPacksDoneAction } from "@store/packs/actions"
import { IAppState, IShowPackOpening, IShowReceivedPacks, ICodeUnlocking } from "@store/appState"
import { Link, useHistory } from "react-router-dom"
import { HeaderFooterAndStrechedContent, OverlayedOnKioskOrFullscreenOnPhone } from "@ui/ViewContainer"
import StickerPopup from "@ui/StickerPopup"
import CloseIcon from "@ui/CloseIcon"
import { useStickerConfig } from "@context/StickerConfig"
import useDevice from "@helper/useDevice"
import useRefSize from "@helper/useRefSize"
import ViewTitle from "@ui/ViewTitle"
import Button from "@ui/Button"
import Sticker from "@ui/Sticker"
import Pack from "@ui/Pack"
import { CSSTransition, SwitchTransition } from "react-transition-group"

export const STICKER_SIZE_PHONE = {
  ONE: 200,
  MANY: 90
}
export const STICKER_SIZE_TABLET = {
  ONE: 220,
  MANY: 140
}

const EnterForm: React.FC<{submitCode(code: string): void, error?: string}> = ({ submitCode, error }) => {
  const [ code, setCode ] = useState("")

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)

  const submitOnEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      submitCode(code)
    }
  }

  return <div className="neo__enterCode__enterForm">
    <div className="neo__enterCode__enterForm__inputHolder">
      <input placeholder={I18n.t("desk.packs.enter_code_placeholder")} type="text" value={code} onKeyDown={submitOnEnter} onChange={onCodeChange} />
    </div>
    <div className="neo__enterCode__enterForm__errorHolder">
      {error && error.length > 0 &&
        <div className="neo__enterCode__enterForm__error">{error}</div>}
    </div>
    <Button type="primaryOnDark" onClick={() => submitCode(code)}>{I18n.t("desk.packs.btn_code")}</Button>
  </div>
}

type GenerateViewProps =
  | {
      type: "enter"
      error?: string
      submitCode(code: string): void
    }
  | {
      type: "received"
      numPacks: number
      stickers: number[]
      enterAnother(): void
    }

export interface EnterCodeViewElements {
  Title?: React.ReactNode
  Body: React.ReactNode
  Foot?: React.ReactNode
  stickerForPopup?: Base.Api.Sticker
  clearStickerForPopup(): void
  state: GenerateViewProps
}

export const generateViewElements = (state: GenerateViewProps, conf?: {stickerSizePhone?: typeof STICKER_SIZE_PHONE, stickerSizeTablet?: typeof STICKER_SIZE_TABLET}): EnterCodeViewElements => {
  const { getSticker } = useStickerConfig()
  const { isPhone, isTablet } = useDevice()
  const [ stickerForPopup, setStickerForPopup ] = useState<Base.Api.Sticker>()

  const stickerSizePhone = conf?.stickerSizePhone || STICKER_SIZE_PHONE
  const stickerSizeTablet = conf?.stickerSizeTablet || STICKER_SIZE_TABLET
  
  const onlyOnePack = state.type === "received" && state.numPacks === 1 && state.stickers.length === 0
  const numReceivingElements = state.type === "received"
    ? state.numPacks + state.stickers.length
    : 0
  const thingSize = (isPhone ? stickerSizePhone : stickerSizeTablet)[numReceivingElements > 1 ? "MANY" : "ONE"]

  return {
    stickerForPopup,
    clearStickerForPopup: () => setStickerForPopup(undefined),
    Title: state.type === "enter"
      ? <>{I18n.t("desk.packs.title_get")}</>
      : undefined,
    Foot: state.type === "received"
      ? <div className="neo__enterCode__receivedFooter">
          <Button type="primaryOnDark" onClick={() => state.enterAnother()}>{I18n.t("teaser.enter_code.action_enter_more")}</Button>
        </div>
      : undefined,
    Body: state.type === "received"
      ? <>
          {_.times(state.numPacks, (num) => {
            return <div key={`pack_${num}`} className={`neo__enterCode__receivedThingHolder ${onlyOnePack ? "--onlyOnePack" : ""}`} style={{width: `${thingSize}px`}}>
              <Pack size={thingSize} shine={onlyOnePack ? {durationMillis: 1500, delayMillis: (num + 1) * 300} : undefined} />
            </div>
          })}
          {_.map(state.stickers, (sId, index) => {
            const sticker = getSticker(sId)
            return sticker
              ? <div key={`sticker_${index}`} className="neo__enterCode__receivedThingHolder" style={{width: `${thingSize}px`}}>
                  <Sticker sticker={sticker} onClick={() => setStickerForPopup(sticker)} rotation="correctSideUp" size={thingSize} />
                </div>
              : null
            })}
        </>
      : <div className="neo__enterCode__enterForm__holder">
          <EnterForm submitCode={state.submitCode} error={state.error}/>
        </div>,
    state
  }
}

const useEnterCodeView = (conf?: {stickerSizePhone?: typeof STICKER_SIZE_PHONE, stickerSizeTablet?: typeof STICKER_SIZE_TABLET}) => {
  const dispatch = useDispatch()

  const { showPackOpening, showReceivedPacks, codeUnlocking } = useSelector<IAppState, {codeUnlocking: ICodeUnlocking, showPackOpening?: IShowPackOpening, showReceivedPacks: IShowReceivedPacks}>((state) => {
    return {
      showPackOpening: state.showPackOpening,
      showReceivedPacks: state.showReceivedPacks,
      codeUnlocking: state.codeUnlocking
    }
  })
  
  const didReceive = showPackOpening || showReceivedPacks
  
  const clearShowings = useCallback(() => {
    dispatch(resetCodeUnlockingAction())
    dispatch(showPackOpeningDoneAction())
    dispatch(showReceivedPacksDoneAction())
  }, [])
  
  useEffect(() => { // clear code unlocking on unmount
    clearShowings() // also clear on mount (maybe some get-free-packs ani is still running and uncleared)
  
    return () => {
      clearShowings()
    }
  }, [])
  
  const enterAnother = () => {
    clearShowings()
  }
  
  const submitCode = (code: string) => {
    dispatch(requestUnlockPackAction(code))
  }
  
  return generateViewElements(
    didReceive
      ? {
          type: "received",
          numPacks: showReceivedPacks?.amount || 0,
          stickers: showPackOpening?.receivedStickers || [],
          enterAnother
        }
      : {
          type: "enter",
          error: codeUnlocking
            ? (codeUnlocking.codeValid ? undefined : getErrorMessage(codeUnlocking?.error))
            : undefined,
          submitCode
        }, conf)
}

export default useEnterCodeView
