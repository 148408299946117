import React, { createContext, useContext } from "react"

interface IAssetPathContext {
  getAssetPath(assetName: string): string
}

const AssetPathContext = createContext<IAssetPathContext>({getAssetPath: () => ""})
AssetPathContext.displayName = "AssetPathContext"

interface WithAssetPathProps {
  manifest: {[key: string]: string}
  assetHost: string
}

export const WithAssetPath: React.FC<WithAssetPathProps> = ({ manifest, assetHost, children }) => {
  const getAssetPath = (assetName: string) => {
    if (!!manifest[assetName]) {
      return assetHost + "/" + manifest[assetName]
    } else { return "" }
  }
  return <AssetPathContext.Provider value={{ getAssetPath }}>
    {children}
  </AssetPathContext.Provider>
}

export const useAssetPath = () => useContext(AssetPathContext)
