import { initReducer, useInit } from "./init"
import { pollReducer, usePoll } from "./poll"
import { miscReducers } from "./misc"
import { packsReducer } from "./packs/reducer"
import { usePacks } from "./packs"
import { useOwnUser, ownUserReducer } from "./ownUser"

const appReducers = [
  initReducer,
  pollReducer,
  packsReducer,
  ownUserReducer,
  miscReducers
]

export const useAllFeatures = () => {
  useInit()
  usePoll()
  usePacks()
  useOwnUser()
}

export default appReducers
