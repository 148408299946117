import { IApi, useApi } from "@api"
import _ from "lodash"
import { useEffect } from "react"
import { useDispatch, useSelector, useStore } from "react-redux"

import { AppThunk } from "./"
import appReducers from "./appReducers"
import { IAppState } from "./appState"

export const POLL_RECEIVE_INTERVAL = "POLL_RECEIVE_INTERVAL"
export const POLL = "POLL"

interface PollAction {
  type: typeof POLL
}

interface ReceivedPollAction {
  type: typeof POLL_RECEIVE_INTERVAL
  millis: number
}

export type ActionTypes = PollAction | ReceivedPollAction

export const pollReducer = (
  state: IAppState,
  action: ActionTypes
): IAppState => {
  switch (action.type) {
    case POLL:
      return state
    case POLL_RECEIVE_INTERVAL:
      return {
        ...state,
        pollIntervalMillis: action.millis
      }
  }
  return state
}

export const pollAction = (): AppThunk => (dispatch, getState, api) => {
  dispatch({ type: POLL })
  api.send({ action: "poll" })
}

export const receivedPollAction = (millis: number): ReceivedPollAction => {
  return {
    type: POLL_RECEIVE_INTERVAL,
    millis
  }
}

export const usePoll = () => {
  const api = useApi<Poll.Api.Request, Poll.Api.Response>()
  const dispatch = useDispatch()
  const pollIntervalMillis = useSelector<IAppState, number | undefined>((state) => state.pollIntervalMillis)

  useEffect(() => { // listen to server
    const listener = api.on((msg) => {
      if (msg.action === "poll_interval") {
        dispatch(receivedPollAction(msg.millis))
      }
    })

    return () => { // cleanup
      api.off(listener)
    }
  }, [])

  useEffect(() => { // send poll in correct interval
    let interval: number

    if (_.isNumber(pollIntervalMillis) && pollIntervalMillis > 10) {
      interval = window.setInterval(() => {
        dispatch(pollAction())
      }, pollIntervalMillis)
    }
    return () => { // cleanup
      window.clearInterval(interval)
    }
  }, [pollIntervalMillis])
}
