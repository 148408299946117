import CloseIcon from "@ui/CloseIcon"
import { CenteredPopup } from "@ui/ViewContainer"
import React, { useCallback, useRef } from "react"
import I18n from "i18n-js"
import Button from "@ui/Button"
import useOnClickOutside from "@helper/useOnClickOutside"

import "./NotForGuestsPopup.scss"
import { useCompleteAccount } from "@context/CompleteAccountContext"

export const NotForGuestsPopup: React.FC<{closePopup?(): void, noFullScreenOnPhone?: boolean}> = ({ closePopup, noFullScreenOnPhone }) => {
  const domRef = useRef<HTMLDivElement>()

  const { completeAccount } = useCompleteAccount()
  useOnClickOutside(domRef, closePopup ? closePopup : () => {})

  return <CenteredPopup noFullScreenOnPhone={noFullScreenOnPhone}>
    <div ref={(ref: any) => { if (ref) { domRef.current = ref }}} className="neo__notForGuests">
      <div className="neo__notForGuests__inner">
        {closePopup && <CloseIcon className="neo__notForGuests__closeIcon" onClick={() => closePopup()} />}
        <div className="neo__notForGuests__panel">
          <h3>{I18n.t("teaser.guests_cant_unlock_codes")}</h3>
          <p>
            <Button type="primaryOnLight" onClick={() => completeAccount()}>{I18n.t("guest_restriction.btn_complete")}</Button>
          </p>
        </div>
      </div>
    </div>
  </CenteredPopup>
}

export default NotForGuestsPopup
