import React from "react"
import I18n from "i18n-js"

import "./FooterWithAppLinks.scss"
import { IconUnlocked } from "@ui/Icon"
import { useAppLinks } from "@context/AppLinksContext"

const FooterWithAppLinks: React.FC = () => {
  const { ios, android } = useAppLinks()

  return <div className="neo__footerWithAppLinks">
    <div className="neo__footerWithAppLinks__icon">
      <IconUnlocked />
    </div>
    <h2>{I18n.t("teaser.apps_title")}</h2>
    <div className="neo__footerWithAppLinks__links">
      {ios
        ? <a className="neo__footerWithAppLinks__ios" target="_blank" href={ios} />
        : <span className="neo__footerWithAppLinks__ios" />}
      {android
        ? <a className="neo__footerWithAppLinks__android" target="_blank" href={android} />
        : <span className="neo__footerWithAppLinks__android" />}
    </div>
  </div>
}

export default FooterWithAppLinks
