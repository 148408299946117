import React, { useEffect, useState } from "react"

const useForceUpdateEveryMillis = (millis: number) => {
  const [ cnt, setCnt ] = useState(0)

  useEffect(() => {
    let interval: number
    if (millis > 0) {
      interval = window.setInterval(() => setCnt((c) => c + 1), millis)
    }

    return () => { // cleanup
      window.clearInterval(interval)
    }
  }, [millis])
}

export default useForceUpdateEveryMillis
