import React from "react"

import LoadingSpinner from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"

const Sandbox: React.FC = () => {
  return <>
    <SectionTitle>CloseIcon</SectionTitle>
    <BlockTitle>onLight</BlockTitle>
    <Block centered>
      <LoadingSpinner type="onLight" />
    </Block>
    <BlockTitle>onDark</BlockTitle>
    <Block centered darkBg>
      <LoadingSpinner type="onDark" />
    </Block>
  </>
}

export default ["Ui", Sandbox]
