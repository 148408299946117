import React from "react"

import AmountBubble from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"

const Sandbox: React.FC = () => {
  return <>
    <SectionTitle>AmountBubble</SectionTitle>
    <Block centered>
      <AmountBubble amount={4} />
      <AmountBubble amount={0} />
      <AmountBubble amount={99} />
      <AmountBubble amount={123} />
    </Block>
  </>
}

export default ["Ui", Sandbox]
