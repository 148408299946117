import { IApi, useApi } from "@api"
import _ from "lodash"
import { useEffect } from "react"
import { useDispatch, useStore } from "react-redux"

import { AppThunk } from "./"
import appReducers from "./appReducers"
import { IAppState } from "./appState"

export const INIT_REQUEST = "INIT_REQUEST"
export const INIT_RECEIVED = "INIT_RECEIVED"
export const INIT_DONE = "INIT_DONE"

interface RequestInitAction {
  type: typeof INIT_REQUEST
}

interface SetInitDoneAction {
  type: typeof INIT_DONE
}

interface ReceivedInitAction {
  type: typeof INIT_RECEIVED
  init: Init.Api.Response
}

export type ActionTypes = RequestInitAction | ReceivedInitAction | SetInitDoneAction

export const initReducer = (
  state: IAppState,
  action: ActionTypes
): IAppState => {
  switch (action.type) {
    case INIT_REQUEST:
      return state
    case INIT_RECEIVED:
      return {
        ...state,
        album: action.init.stacks.album,
        tempStack: action.init.stacks.temp,
        swapStack: action.init.stacks.swap
      }
    case INIT_DONE:
      return {
        ...state,
        initDone: true
      }
  }
  return state
}

export const requestInitAction = (): AppThunk => (dispatch, getState, api) => {
  dispatch({ type: INIT_REQUEST })
  api.send({action: "init"})
}

export const setInitDoneAction = (): SetInitDoneAction => {
  return {
    type: INIT_DONE
  }
}

export const receivedInitAction = (init: Init.Api.Response): ReceivedInitAction => {
  return {
    type: INIT_RECEIVED,
    init
  }
}

export const useInit = () => {
  const api = useApi<Init.Api.Request, Init.Api.Response>()
  const dispatch = useDispatch()

  useEffect(() => {
    let timeout: number
    const listener = api.on((msg) => {
      if (msg.action === "init") {
        dispatch(receivedInitAction(msg))
        timeout = window.setTimeout(() => dispatch(setInitDoneAction()), 500)
      }
    })

    return () => { // cleanup
      api.off(listener)
      window.clearTimeout(timeout)
    }
  }, [])
}
