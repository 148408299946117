import React from "react"

import Button from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"

const Sandbox: React.FC = () => {
  return <>
    <SectionTitle>Button</SectionTitle>
    <Block centered darkBg>
      <Button type="primaryOnDark">Primary on dark</Button>
      <Button type="secondaryOnDark">Secondary on dark</Button>
      <Button type="primaryOnDark" disabled>Disabled (all same style)</Button>
    </Block>
    <Block centered>
      <Button type="primaryOnLight">Primary on light</Button>
      <Button type="secondaryOnLight">Secondary on light</Button>
    </Block>
  </>
}

export default ["Ui", Sandbox]
