import React, { ReactNode, useCallback, useRef, useState } from "react"
import _ from "lodash"
import {
  Switch,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  HashRouter
} from "react-router-dom"

import "./Sandbox.sass"
import { AppProps } from "../App"
import LoadConfigs from "@context/LoadConfigs"
import { WithStore } from "@store"

interface SandboxEntry {
  name: string
  Comp: (() => JSX.Element)[]
}
const _sandboxes: SandboxEntry[] = []

const SectionTitle: React.FC = ({ children }) => { return <h2 className={"sandbox__sectionTitle"}>{children}</h2> }
const BlockTitle: React.FC<{info?: any}> = ({ children, info }) => { return <h2 className={"sandbox__blockTitle"} title={info || null}>{children}</h2> }
const Block: React.FC<{centered?: boolean, whiteBg?: boolean, darkBg?: boolean, mediumBg?: boolean, forSidebar?: boolean}> = ({ children, whiteBg, darkBg, mediumBg, forSidebar, centered }) => {
  const variants = [
    "sandbox__block",
    darkBg ? "sandbox__darkBg buttonGrouper--onDarkBackdrop" : "",
    forSidebar ? "sandbox__blockForSidebar" : "",
    mediumBg ? "sandbox__mediumBg buttonGrouper--onMediumBackdrop" : "",
    whiteBg ? "sandbox__whiteBg" : "",
    centered ? "sandbox__block--centered" : ""
  ].join(" ")
  return <div className={`layout__contentRow ${variants}`}>{children}</div>
}
const Note: React.FC = ({ children }) => { return <div className={"sandbox__note"}>{children}</div> }
const Todo: React.FC = ({ children }) => { return <div className={"sandbox__todo"}>{children}</div> }

const PageTitle: React.FC = ({ children }) => {
  return (
    <h1 className="sandbox__pageTitle">
      {children}
    </h1>
  )
}

export interface IScenario {
  title: string
  note?: string
  highlightNote?: boolean
  el: (setScenarioIndex: SetScenarioIndex) => JSX.Element
  needsRemount?: boolean
  noWrapper?: boolean
}

type SetScenarioIndex = (index?: number) => void
interface ScenariosProps {
  scenarios: IScenario[],
  startingScenario?: number
  ifNoneSelected?(): JSX.Element
  WrapWith?: React.FC
}

export const Scenarios: React.FC<ScenariosProps> = ({ scenarios, startingScenario, ifNoneSelected, WrapWith }) => {
  const [ mountKey, setMountKey ] = useState(0)
  const prevScenarioIndex = useRef<number>()
  const [ scenarioIndex, setScenarioIndex ] = useState(startingScenario)
  const current = useCallback(() => {
    if (_.isNumber(scenarioIndex)) {
      const s = scenarios[scenarioIndex]
      const prev = prevScenarioIndex.current
      prevScenarioIndex.current = scenarioIndex
      if (s.needsRemount && _.isNumber(prev) && prev !== scenarioIndex) { setMountKey((prev) => prev + 1) }
      return s.el(setScenarioIndex)
    }
    return ifNoneSelected ? ifNoneSelected() : null
  }, [scenarioIndex])
  const currentSc = _.isNumber(scenarioIndex) ? scenarios[scenarioIndex] : undefined

  return <>
    <Note>
      <ul>
        {_.map(scenarios, (s, index) => {
          return <li key={index}>
            <a onClick={() => setScenarioIndex(index)}>
              {index === scenarioIndex ? <strong>{s.title}</strong> : s.title}
            </a>
            {s.note && <>{" "}<span className={s.highlightNote ? "sandbox__inlineHighlight" : undefined}>{s.note}</span></>}
          </li>
        })}
      </ul>
    </Note>
    <Block>{WrapWith && (!currentSc || !currentSc.noWrapper)
      ? <WrapWith key={mountKey}><_WRAP_RENDERING_TO_REVENT_HOOK_BUG el={current} /></WrapWith>
      : <_WRAP_RENDERING_TO_REVENT_HOOK_BUG el={current} />}</Block>
  </>
}

const _WRAP_RENDERING_TO_REVENT_HOOK_BUG = ({el}: {el: () => ReactNode}) => <>{el()}</>

const _Sandbox: React.FC = () => {
  const { path, url } = useRouteMatch()

  return (
    <>
      <div className={"sandbox__navi"}>
        <span>Sandbox</span>
        {_.map(_.sortBy(_sandboxes, (i) => i.name.toLocaleLowerCase()), ({ name, Comp }) => {
          return (
            <NavLink className={"sandbox__navi__link"} activeClassName={"sandbox__navi__link--active"} key={name} to={`/${_.kebabCase(name)}`}>{(name[0] || "").toUpperCase() + name.slice(1)}</NavLink>
          )
        })}
      </div>
      <div className="sandbox__body">
        <Switch>
          {_.map(_sandboxes, ({ name, Comp }) => {
            const Comps = _.isArray(Comp) ? Comp : [Comp] // rebuilt to also support arrays, but default naming still expects single comp to be more clear
            return (
              <Route key={name} path={`/${_.kebabCase(name)}`}>
                {_.map((Comps), (C, index) => { return <C key={index} /> })}
              </Route>
            )
          })}
        </Switch>
      </div>
    </>
  )
}

const Sandbox: React.FC<AppProps> = ({ios_url, android_url, api_host, asset_host, locale}) => {
  return (
    <div className="neo__teaserExperience">
      <LoadConfigs assetHost={asset_host} locale={locale}>
        <WithStore forSandbox>
          <HashRouter><_Sandbox /></HashRouter>
        </WithStore>
      </LoadConfigs>
    </div>
  )
}

export { SectionTitle, PageTitle, BlockTitle, Block, Note, Todo }

export default Sandbox

// pack all sandbox files in dev ENV
if (true || process.env.NODE_ENV === "development") {
  const sandboxFilesContext = (require as any).context("../", true, /\.sandbox\.(js|tsx)$/)
  sandboxFilesContext.keys().forEach((key: string) => {
    let name: string
    let Comp: any
    const exported: any = sandboxFilesContext(key).default
    if (_.isArray(exported)) { // add to exisiting sandbox page
      name = exported[0]
      Comp = exported[1]
    } else {
      name = key.split("/")[key.split("/").length - 1].split(".sandbox.js")[0].split(".sandbox.tsx")[0]
      Comp = exported
    }
    if (!_.isArray(Comp)) { Comp = [Comp] } // normalize Comp to array

    let sandboxEntry = _.find(_sandboxes, { name })
    if (!sandboxEntry) {
      const initialComps = _.isArray(exported) ? [ () => { return <PageTitle>{_.capitalize(name)}</PageTitle> } ] : []
      sandboxEntry = { name, Comp: initialComps }
      _sandboxes.push(sandboxEntry as SandboxEntry)
    }
    sandboxEntry.Comp = sandboxEntry.Comp.concat(Comp)
  })
}
