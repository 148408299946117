import React, { useState } from "react"
import _ from "lodash"

import { PageTitle, SectionTitle, Block, BlockTitle, Note, Scenarios, IScenario } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"
import { useStickerConfig } from "@context/StickerConfig"
import { useEnterCodeScenarios } from "@view/EnterCode/EnterCode.sandbox"
import { KioskWrapperWhenOnlyEnterCode, NotForGuests, OnlyEnterCodeInner, WrapperWhileLoadingConfigsOnlyEnterCode } from "."
import LoadingSpinner from "@ui/LoadingSpinner"

const Sandbox: React.FC = () => {

  let scenarios = useEnterCodeScenarios((conf) => {
    return {
      title: conf.title,
      el: (setScenarioIndex) => <OnlyEnterCodeInner view={conf.genView(setScenarioIndex)} />
    }
  })
  scenarios = [
    {
      title: "while loading configs",
      el: () => <WrapperWhileLoadingConfigsOnlyEnterCode><LoadingSpinner type="onDark" /></WrapperWhileLoadingConfigsOnlyEnterCode>,
      noWrapper: true
    },
    {
      title: "Not for guests",
      el: () => <KioskWrapperWhenOnlyEnterCode>
        <NotForGuests />
      </KioskWrapperWhenOnlyEnterCode>
    },
    ...scenarios
  ]

  return <>
    <PageTitle>Enter Code</PageTitle>

    <Scenarios scenarios={scenarios} WrapWith={({children}) => <KioskWrapperWhenOnlyEnterCode>{children}</KioskWrapperWhenOnlyEnterCode>}/>
  </>
}

export default Sandbox
