import CloseIcon from "@ui/CloseIcon"
import { CenteredPopup } from "@ui/ViewContainer"
import React, { useCallback, useRef } from "react"
import I18n from "i18n-js"
import useOnClickOutside from "@helper/useOnClickOutside"

import "./StickerPopup.scss"
import Sticker from "@ui/Sticker"
import useDevice from "@helper/useDevice"

const STICKER_SIZE_PHONE = 260
const STICKER_SIZE_TABLET = 320

export const StickerPopup: React.FC<{sticker: Base.Api.Sticker, closePopup(): void, fullScreen?: boolean}> = ({ sticker, closePopup, fullScreen }) => {
  const domRef = useRef<HTMLDivElement>()
  const { isPhone } = useDevice()

  useOnClickOutside(domRef, closePopup)

  return <CenteredPopup withDarkBackdrop fullScreen={fullScreen}>
    <div ref={(ref: any) => { if (ref) { domRef.current = ref }}} className="neo__downloadAppPopup">
      <div className="neo__stickerPopup__inner">
        <CloseIcon className="neo__stickerPopup__closeIcon" onClick={() => closePopup()} />
        <div className="neo__stickerPopup__stickerHolder">
          <h2>{sticker.label}</h2>
          <Sticker sticker={sticker} rotation="correctSideUp" size={isPhone ? STICKER_SIZE_PHONE : STICKER_SIZE_TABLET} />
        </div>
      </div>
    </div>
  </CenteredPopup>
}

export default StickerPopup
