import CloseIcon from "@ui/CloseIcon"
import { CenteredPopup } from "@ui/ViewContainer"
import React, { useCallback, useRef } from "react"
import I18n from "i18n-js"
import Button from "@ui/Button"
import useOnClickOutside from "@helper/useOnClickOutside"

import "./DownloadAppPopup.scss"
import { useAppLinks } from "@context/AppLinksContext"

export const DownloadAppPopup: React.FC<{closePopup(): void}> = ({ closePopup }) => {
  const domRef = useRef<HTMLDivElement>()
  const { ios, android } = useAppLinks()

  useOnClickOutside(domRef, closePopup)

  return <CenteredPopup>
    <div ref={(ref: any) => { if (ref) { domRef.current = ref }}} className="neo__downloadAppPopup">
      <div className="neo__downloadAppPopup__inner">
        <CloseIcon className="neo__downloadAppPopup__closeIcon" onClick={() => closePopup()} />
        <div className="neo__downloadAppPopup__panel">
          <h3>{I18n.t("scan.web_info.title")}</h3>
          <p>{I18n.t("scan.web_info.info")}</p>
          <div className="neo__downloadAppPopup__links">
            {ios
              ? <a className="neo__downloadAppPopup__ios" target="_blank" href={ios} />
              : <span className="neo__downloadAppPopup__ios" />}
            {android
              ? <a className="neo__downloadAppPopup__android" target="_blank" href={android} />
              : <span className="neo__downloadAppPopup__android" />}
          </div>
        </div>
      </div>
    </div>
  </CenteredPopup>
}

export default DownloadAppPopup
