import { BaseTrackingData } from "@api/tracking"

export interface IAppState {
  connectError?: string
  initDone: boolean
  album: Base.Api.AlbumStack
  swapStack: Base.Api.CardStack
  tempStack: Base.Api.CardStack
  ownUser?: Base.Api.User
  pollIntervalMillis?: number
  packs: IPacksInfo
  showPackOpening?: IShowPackOpening
  codeUnlocking?: ICodeUnlocking
  showReceivedPacks?: IShowReceivedPacks // packs from codes of daily-packs
  trackingData: BaseTrackingData
}

export interface IShowReceivedPacks {
  amount: number
}

export interface IPacksInfo {
  numUnopened: number
  hasNewPacksWaiting: boolean
  nextFreePackInSeconds?: number
  receivedNextFreePackInfoAt?: Date
}

export interface ICodeUnlocking {
  enteredCode?: string
  error?: Base.Api.ErrorValue
  codeValid?: boolean
}

export interface IShowPackOpening {
  receivedStickers?: number[]
  wait?: Packs.Api.OpenPackWait
}

export const initialState: IAppState = {
  initDone: false,
  album: [],
  swapStack: [],
  tempStack: [],
  packs: {
    numUnopened: 0,
    hasNewPacksWaiting: false
  },
  trackingData: {}
}
