import React from "react"

import { IconLocked, IconUnlocked } from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"

const Sandbox: React.FC = () => {
  return <>
    <SectionTitle>Icon</SectionTitle>
    <Block centered>
      <IconLocked />
    </Block>
    <Block centered darkBg>
      <IconUnlocked />
    </Block>
  </>
}

export default ["Ui", Sandbox]
