import React, { useState } from "react"
import _ from "lodash"

import { KioskItemsInner } from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"
import { IPacksInfo } from "@store/appState"
import { useStickerConfig } from "@context/StickerConfig"

const Sandbox: React.FC = () => {
  const [ aDate, setADate ] = useState(new Date())
  const { stickers } = useStickerConfig()
  const [ freePacks, setFreePacks ] = useState({total: 0, amount: 0})

  const emitFreePacks = (amount: number) => {
    setFreePacks((prev) => { return {total: prev.total + amount, amount} })
  }

  const showingDone = () => {
    setFreePacks((prev) => { return {total: prev.total, amount: 0} })
  }

  return <>
    <PageTitle>KioskItems</PageTitle>

    <SectionTitle>Guest (clicking "PROMO CODE" opens popup), timer for unopened packs</SectionTitle>
    <Block>
      <Kiosk>
        <KioskItemsInner stickers={[]} isGuest packs={{hasNewPacksWaiting: false, numUnopened: 5, nextFreePackInSeconds: 1000, receivedNextFreePackInfoAt: aDate}} />
      </Kiosk>
    </Block>

    <SectionTitle>Not Guest (clicking "PROMO CODE" navigates), no stickers</SectionTitle>
    <Block>
      <Kiosk>
        <KioskItemsInner stickers={[]} isGuest={false} packs={{numUnopened: 3, hasNewPacksWaiting: false}} />
      </Kiosk>
    </Block>

    <SectionTitle>Not Guest, some stickers, can get new free packs</SectionTitle>
    <Block>
      <Kiosk>
        <KioskItemsInner stickers={_.chain(stickers).sampleSize(15).map((s) => s.id).value()} isGuest={false} packs={{hasNewPacksWaiting: true, numUnopened: 1, nextFreePackInSeconds: 999, receivedNextFreePackInfoAt: aDate}} />
      </Kiosk>
    </Block>

    <SectionTitle>No stickers, no packs <a onClick={() => emitFreePacks(1)}>+1PACK</a> <a onClick={() => emitFreePacks(2)}>+2</a> <a onClick={() => emitFreePacks(3)}>+3</a>  <a onClick={() => emitFreePacks(20)}>+20</a></SectionTitle>
    <Block>
      <Kiosk>
        <KioskItemsInner
          stickers={[]}
          isGuest={false}
          packs={{
            numUnopened: freePacks.total,
            hasNewPacksWaiting: freePacks.amount > 0,
            nextFreePackInSeconds: 999,
            receivedNextFreePackInfoAt: aDate
          }}
          onShowingReceivedPacksDone={showingDone}
          showReceivedPacks={freePacks.amount > 0 ? {amount: freePacks.amount} : undefined} />
      </Kiosk>
    </Block>
  </>
}

export default Sandbox
