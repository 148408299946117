import React from "react"
import { useStickerConfig } from "@context/StickerConfig"

import "./Sticker.scss"

export type StickerRotation = "correctSideUp" | "forceLandscape" | "forcePortrait"

interface StickerProps {
  sticker: Base.Api.Sticker
  rotation: StickerRotation
  golden?: boolean
  tiltABit?: boolean
  size: number
  withShadow?: boolean
  onClick?(sticker: Base.Api.Sticker): void
}

const STICKER_RATIO = 1.2

const Sticker: React.FC<StickerProps> = ({ sticker, rotation, golden, tiltABit, size, withShadow, onClick }) => {
  const { getStickerImage } = useStickerConfig()

  const className = [
    "neo__sticker",
    withShadow ? "neo__sticker--withShadow" : ""
  ].join(" ")

  const width = sticker.info.rotated ? size : Math.round(size / STICKER_RATIO)
  const height = sticker.info.rotated ? Math.round(size / STICKER_RATIO) : size

  let angle = 0
  if (sticker.info.rotated && rotation === "forcePortrait") {
    angle = 90
  } else if (!sticker.info.rotated && rotation === "forceLandscape") {
    angle = 90
  }
  if (tiltABit) { angle += (sticker.label.length % 10) - 5 }

  const img = getStickerImage(sticker, !!golden)

  return <div className={className} style={{width: `${size}px`, height: `${size}px`}}>
    <div className="neo__sticker__centerer">
      <div className={`neo__sticker__imageHolder ${onClick ? "--withOnClick" : ""}`} style={{width: `${width}px`, height: `${height}px`, transform: `rotate(${angle}deg)`}} onClick={onClick ? () => onClick(sticker) : undefined}>
        <div className="neo__sticker__image" style={{backgroundImage: `url(${img})`}}/>
        {golden && <div className="neo__sticker__goldenShine" />}
      </div>
    </div>
  </div>
}

export default Sticker
