import React, { useEffect, useState, useCallback, ChangeEvent, KeyboardEvent, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import I18n from "i18n-js"
import _ from "lodash"

import "./EnterCode.scss"

import getErrorMessage from "@helper/getErrorMessage"
import { requestUnlockPackAction, resetCodeUnlockingAction, showPackOpeningDoneAction, showReceivedPacksDoneAction } from "@store/packs/actions"
import { IAppState, IShowPackOpening, IShowReceivedPacks, ICodeUnlocking } from "@store/appState"
import { Link, useHistory } from "react-router-dom"
import { HeaderFooterAndStrechedContent, OverlayedOnKioskOrFullscreenOnPhone } from "@ui/ViewContainer"
import StickerPopup from "@ui/StickerPopup"
import CloseIcon from "@ui/CloseIcon"
import { useStickerConfig } from "@context/StickerConfig"
import useDevice from "@helper/useDevice"
import useRefSize from "@helper/useRefSize"
import ViewTitle from "@ui/ViewTitle"
import Button from "@ui/Button"
import Sticker from "@ui/Sticker"
import Pack from "@ui/Pack"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import useEnterCodeView, { EnterCodeViewElements } from "./useEnterCodeView"

export const EnterCodeInner: React.FC<{view: EnterCodeViewElements, onClose(): void}> = ({ view, onClose }) => {
  const contentRef = useRef<HTMLDivElement>()
  const { size: contentSize, recalcSize: recalcContentSize } = useRefSize(contentRef)

  useEffect(() => { // ------------- recalc content size when state-type changes -------------
    recalcContentSize()

    return () => { // cleanup
    }
  }, [view.state.type])

  const contentInnerClassName = [
    "neo__enterCode__content__inner",
    `neo__enterCode__content__inner--${view.state.type}` // "--enter" or "--received"
  ].join(" ")

  return <OverlayedOnKioskOrFullscreenOnPhone withDarkBackdropAboveKiosk className="neo__enterCode">
    {view.stickerForPopup &&
      <StickerPopup sticker={view.stickerForPopup} closePopup={view.clearStickerForPopup} />}
    <CloseIcon onClick={onClose} positionTopRight />
    <SwitchTransition>
      <CSSTransition key={view.state.type} classNames="enterCodeStateFade" timeout={{enter: 500, exit: 300}}>
        <HeaderFooterAndStrechedContent
          className="neo__enterCode__inner"
          contentClassName="neo__enterCode__content"
          ref={contentRef}
          header={view.Title ? <ViewTitle type="onDark">{view.Title}</ViewTitle> : undefined}
          footer={view.Foot}>
          <div className={contentInnerClassName} style={contentSize ? { maxHeight: `${Math.floor(contentSize.height)}px`} : undefined}>
            {view.Body}
          </div>
        </HeaderFooterAndStrechedContent>
      </CSSTransition>
    </SwitchTransition>
  </OverlayedOnKioskOrFullscreenOnPhone>
}

const EnterCode: React.FC<{onlyEnterCode?: boolean}> = () => {
  const history = useHistory()
  const view = useEnterCodeView()

  const onClose = () => history.replace("/")

  return <EnterCodeInner
    view={view}
    onClose={onClose} />
}

export default EnterCode
