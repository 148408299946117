import React, { useState } from "react"
import _ from "lodash"

import Sticker, { StickerRotation } from "./"

import { PageTitle, SectionTitle, Note, Block, BlockTitle } from "@sandbox/Sandbox"
import { useStickerConfig } from "@context/StickerConfig"

const Sandbox: React.FC = () => {
  const { stickers } = useStickerConfig()

  const [ rotation, setRotation ] = useState<StickerRotation>("correctSideUp")
  const [ tiltABit, setTiltABit ] = useState(false)

  return <>
    <PageTitle>Sticker</PageTitle>

    <Note>
      <p>
        <a onClick={() => setTiltABit(!tiltABit)}>Tilt a bit? {tiltABit ? "YES" : "NO"}</a>
      </p>
      <h3>Rotation?</h3>
      <ul>
        <li><a onClick={() => setRotation("correctSideUp")} style={{fontWeight: rotation === "correctSideUp" ? "bold" : "normal"}}>Correct side up</a></li>
        <li><a onClick={() => setRotation("forceLandscape")} style={{fontWeight: rotation === "forceLandscape" ? "bold" : "normal"}}>Force landscape</a></li>
        <li><a onClick={() => setRotation("forcePortrait")} style={{fontWeight: rotation === "forcePortrait" ? "bold" : "normal"}}>Force portrait</a></li>
      </ul>
    </Note>
    <Block>
      {_.map(stickers, (s) => {
        return <Sticker key={s.id} tiltABit={tiltABit} sticker={s} rotation={rotation} size={80} />
      })}
    </Block>
  </>
}

export default Sandbox
