import React, { createContext, useContext } from "react"

interface ICompleteAccountContext {
  completeAccount(): void
}

export const CompleteAccountContext = createContext<ICompleteAccountContext>({ completeAccount: () => {} })
CompleteAccountContext.displayName = "CompleteAccountContext"

export const useCompleteAccount = () => useContext(CompleteAccountContext)
