import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import I18n from "i18n-js"

import "./OpenPack.scss"

import { IAppState, IShowPackOpening } from "@store/appState"
import { Link, useHistory } from "react-router-dom"
import prettyCountdownSeconds from "@helper/prettyCountdownSeconds"
import { useStickerConfig } from "@context/StickerConfig"
import { requestOpenPackAction, showPackOpeningDoneAction } from "@store/packs/actions"
import { HeaderFooterAndStrechedContent, OverlayedOnKioskOrFullscreenOnPhone } from "@ui/ViewContainer"
import CloseIcon from "@ui/CloseIcon"
import ViewTitle from "@ui/ViewTitle"
import Button from "@ui/Button"
import { useCompleteAccount } from "@context/CompleteAccountContext"
import useWaitAtLeastMillis from "@helper/useWaitAtLeastMillis"
import Sticker from "@ui/Sticker"
import useDevice from "@helper/useDevice"
import useRefSize from "@helper/useRefSize"
import Pack from "@ui/Pack"
import StickerPopup from "@ui/StickerPopup"

interface OpenPackInnerProps {
  state: {
    type: "loading"
  } | {
    type: "opened"
    stickers: number[]
  } | {
    type: "cantOpen"
    wait?: Packs.Api.OpenPackWait
    isGuest: boolean
  }
  onClose(): void
}

const SIZE = {
  tablet: {
    pack: 260,
    stickerBehindPack: 200,
    maxFannedOutSticker: 180,
    sideMinMargin: 60
  },
  phone: {
    pack: 220,
    stickerBehindPack: 180,
    maxFannedOutSticker: 180,
    sideMinMargin: 20
  }
}

interface StickerPositioning {
  stickerSize: number
  stickerPos: {left: number, top: number}[]
}

const RIP_PACK_OPEN_MILLIS = 800
const FAN_OUT_STICKERS_AFTER_PACK_OPEN_MILLIS = 200

export const OpenPackInner: React.FC<OpenPackInnerProps> = ({state, onClose}) => {
  const contentRef = useRef<HTMLDivElement>()
  const { size: contentSize } = useRefSize(contentRef)
  const [ stickerPositioning, setStickerPositioning ] = useState<StickerPositioning>()
  const [ stickerForPopup, setStickerForPopup ] = useState<Base.Api.Sticker>()

  const { completeAccount } = useCompleteAccount()
  const { getStickerImage, getSticker } = useStickerConfig()

  const { isPhone, isTablet } = useDevice()
  const size = SIZE[isPhone ? "phone" : "tablet"]

  const [ behaviour, setBehaviour ] = useState<{
    pack: "jiggle" | "open" | "stay_closed"
    packPosition: "center" | "bottom"
    stickers: "onePack" | "fanOut"
  }>({pack: "jiggle", packPosition: "center", stickers: "onePack"})

  useEffect(() => { // start opening ani, move pack and stickers around
    let timeout: number
    if (state.type === "opened") {
      setBehaviour((prev) => { return { ...prev, pack: "open" } })
      timeout = window.setTimeout(() => {
        setBehaviour((prev) => { return { ...prev, packPosition: "bottom" } })
        timeout = window.setTimeout(() => {
          setBehaviour((prev) => { return { ...prev, stickers: "fanOut" } })
        }, FAN_OUT_STICKERS_AFTER_PACK_OPEN_MILLIS)
      }, RIP_PACK_OPEN_MILLIS)
    } else if (state.type === "cantOpen") {
      setBehaviour((prev) => { return { ...prev, pack: "stay_closed", packPosition: "bottom" } })
    }

    return () => { // cleanup
      window.clearTimeout(timeout)
    }
  }, [state.type])

  useEffect(() => { // recalc stickerpos on resize + when fanning out
    if (contentSize && state.type === "opened" && state.stickers.length > 0) {
      const fanOut = behaviour.stickers === "fanOut"
      const numStickers = state.stickers.length

      if (!fanOut) {
        // stickers still "in pack"
        setStickerPositioning({
          stickerSize: size.stickerBehindPack,
          stickerPos: _.map(state.stickers, (s, index) => {
            return { left: contentSize.width / 2, top: contentSize.height / 2 }
          })
        })
      } else { // fanning out stickers
        const areaHeight = contentSize.height - size.pack / 4 // leave some space below
        const areaWidth = contentSize.width
        const numRows = numStickers > 3 ? 2 : 1
        const perRow = Math.ceil(numStickers / numRows)
        const stickerMargin = 2
        const stickerSize = Math.min(((areaWidth - 2 * size.sideMinMargin) / perRow) - 2 * stickerMargin, size.maxFannedOutSticker) // don't get larger than when in pack
        const stickerSizeWithMargin = stickerSize + 2 * stickerMargin

        setStickerPositioning({
          stickerSize,
          stickerPos: _.map(state.stickers, (s, index) => {
            const rowIndex = Math.floor(index / perRow)
            const stickerInThisRowIndex = index % perRow
            const numStickersInThisRow = rowIndex === numRows - 1
              ? numStickers - perRow * rowIndex // last row
              : perRow // normal row
            const leftRowOffset = (areaWidth - numStickersInThisRow * stickerSizeWithMargin) / 2 + stickerSizeWithMargin / 2 // to center stickers
            return {
              top: areaHeight / 2 - (numRows * stickerSizeWithMargin) / 2 + rowIndex * stickerSizeWithMargin + stickerSizeWithMargin / 2,
              left: leftRowOffset + stickerSizeWithMargin * stickerInThisRowIndex
            }
          })
        })
      }
    }

    return () => {} // cleanup
  }, [
    contentSize?.height,
    contentSize?.width,
    behaviour.stickers,
    isPhone,
    (state.type === "opened" && state.stickers.length) || 0
  ])

  return <OverlayedOnKioskOrFullscreenOnPhone withDarkBackdropAboveKiosk className="neo__openPack">
    {stickerForPopup && <StickerPopup sticker={stickerForPopup} closePopup={() => setStickerForPopup(undefined)} />}
    <CloseIcon onClick={() => onClose()} positionTopRight />
    <HeaderFooterAndStrechedContent
      ref={contentRef}
      header={<ViewTitle type="onDark">{I18n.t("open_pack.title")}</ViewTitle>} >

      {contentSize &&
        <>
          {/* -------------------- Stickers -------------------- */}
          {state.type === "opened" && stickerPositioning &&
            <div className="neo__openPack__stickersHolder">
              {_.map(state.stickers, (stickerId, index) => {
              const sticker = getSticker(stickerId)
              const positioning = stickerPositioning.stickerPos[index] || {left: 0, top: 0}

              return sticker
                ? <div
                    key={`${index}_${stickerId}`}
                    className="neo__openPack__stickerHolder"
                    style={{left: `${positioning.left}px`, top: `${positioning.top}px`}}>
                    <Sticker onClick={() => setStickerForPopup(sticker)} sticker={sticker} size={stickerPositioning.stickerSize} rotation={behaviour.stickers === "onePack" ? "forcePortrait" : "correctSideUp"} />
                  </div>
                : null})}
            </div>}

          {/* ---------------------- Pack ---------------------- */}
          <div
            className="neo__openPack__packHolder"
            style={{
              left: `${Math.floor(contentSize.width / 2)}px`,
              top: `${Math.floor(behaviour.packPosition === "bottom" ? (contentSize.height + size.pack * .25) : (contentSize.height / 2))}px`
            }} >
              <div className={`neo__openPack__packBehaviour neo__openPack__packBehaviour--${behaviour.pack}`}>
                <Pack size={size.pack} open={behaviour.pack === "open"} />
              </div>
          </div>

          {/* --------------- Can't open message --------------- */}
          {state.type === "cantOpen" &&
            <div className="neo__openPack__cantOpenHolder">
              <div className="neo__openPack__cantOpen">
                {state.wait
                  ? <>
                      <span className="neo__openPack__cantOpen__info" dangerouslySetInnerHTML={{__html: state.wait.reason}}/>
                      <span className="neo__openPack__cantOpen__countdown" >{prettyCountdownSeconds(state.wait.countdown_seconds)}</span >
                      {state.isGuest &&
                        <Button onClick={() => completeAccount()} type="primaryOnLight">{I18n.t("swap.market.guest_overlay.btn_complete_account")}</Button>}
                    </>
                  : <div>{I18n.t("errors.kill")/* should not happen, we should always have 'wait' */}</div>}
              </div>
            </div>}
        </>}
    </HeaderFooterAndStrechedContent>
  </OverlayedOnKioskOrFullscreenOnPhone>
}

const OpenPack: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const waitedABit = useWaitAtLeastMillis(1000)
  const { showPackOpening, isGuest } = useSelector<IAppState, {showPackOpening?: IShowPackOpening, isGuest: boolean}>((state) => {
    return { showPackOpening: state.showPackOpening, isGuest: !!state.ownUser?.guest }
  })

  useEffect(() => { // request open on mount
    dispatch(requestOpenPackAction())

    return () => { // cleanup
      dispatch(showPackOpeningDoneAction())
    }
  }, [])

  const onClose = () => history.replace("/")

  return <OpenPackInner
    state={(showPackOpening && waitedABit)
      ? (showPackOpening.receivedStickers
          ? {
              type: "opened",
              stickers: showPackOpening.receivedStickers
            }
          : {
              type: "cantOpen",
              wait: showPackOpening.wait,
              isGuest
            })
      : {
          type: "loading"
        }
    }
    onClose={onClose} />
}

export default OpenPack
