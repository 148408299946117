import CloseIcon from "@ui/CloseIcon"
import { CenteredPopup } from "@ui/ViewContainer"
import React, { useCallback, useRef } from "react"
import I18n from "i18n-js"
import Button from "@ui/Button"
import useOnClickOutside from "@helper/useOnClickOutside"

import "./ConnectErrorPopup.scss"
import { useCompleteAccount } from "@context/CompleteAccountContext"

export const ConnectErrorPopup: React.FC<{closePopup(): void, msg: string}> = ({ closePopup, msg }) => {
  const domRef = useRef<HTMLDivElement>()

  const { completeAccount } = useCompleteAccount()
  useOnClickOutside(domRef, closePopup)

  return <CenteredPopup>
    <div ref={(ref: any) => { if (ref) { domRef.current = ref }}} className="neo__connectError">
      <div className="neo__connectError__inner">
        <CloseIcon className="neo__connectError__closeIcon" onClick={() => closePopup()} />
        <div className="neo__connectError__panel">
          <p className="neo__connectError__msg" dangerouslySetInnerHTML={{__html: msg}} />
          <p>
            <Button type="primaryOnLight" onClick={() => completeAccount()}>{I18n.t("swap.market.guest_overlay.btn_complete_account")}</Button>
          </p>
        </div>
      </div>
    </div>
  </CenteredPopup>
}

export default ConnectErrorPopup
