import useDevice from "@helper/useDevice"
import React, { forwardRef, MutableRefObject, PropsWithChildren, ReactNode } from "react"

import "./ViewContainer.scss"

export const CenteredInKioskView: React.FC<{className?: string}> = ({ className, children }) => {
  const _className = [
    "neo__viewContainer__centeredInKioskView",
    className || ""
  ].join(" ")

  return <div className={_className}>{children}</div>
}

export const OverlayedOnKioskOrFullscreenOnPhone: React.FC<{className?: string, withDarkBackdropAboveKiosk?: boolean, centerChild?: boolean}> = ({ children, withDarkBackdropAboveKiosk, centerChild, className }) => {
  const { isTablet } = useDevice()
  const _className = [
    "neo__viewContainer__overlayView",
    centerChild ? "neo__viewContainer__overlayView--centerChild" : "",
    withDarkBackdropAboveKiosk || !isTablet ? "neo__viewContainer__overlayView--withDarkBackdrop" : "",
    className || ""
  ].join(" ")
  return <div className={_className}>
    <div className="neo__viewContainer__overlayView__contentWrapper">
      {children}
    </div>
  </div>
}

export const HeaderFooterAndStrechedContent = forwardRef<HTMLDivElement | undefined, PropsWithChildren<{contentClassName?: string, header?: ReactNode, footer?: ReactNode, className?: string}>>(({ header, footer, children: content, contentClassName, className }, forwardedRef) => {
  const setForwardedRef = (node: HTMLDivElement | null) => {
    if (!node) { return }
    if (typeof forwardedRef === "function") {
      forwardedRef(node)
    } else if (forwardedRef) {
      (forwardedRef as MutableRefObject<HTMLDivElement>).current = node
    }
  }

  return <div className={`neo__viewContainer__HFC ${className ? className : ""}`}>
    <div className="neo__viewContainer__HFC__header">{header}</div>
    <div className={`neo__viewContainer__HFC__content ${contentClassName || ""}`} ref={setForwardedRef}>{content}</div>
    <div className="neo__viewContainer__HFC__footer">{footer}</div>
  </div>
})

export const CenteredPopup: React.FC<{className?: string, withDarkBackdrop?: boolean, fullScreen?: boolean, noFullScreenOnPhone?: boolean}> = ({ children, className, withDarkBackdrop, fullScreen, noFullScreenOnPhone }) => {
  const { isTablet } = useDevice()
  const _className = [
    "neo__viewContainer__centeredPopup",
    noFullScreenOnPhone ? "neo__viewContainer__centeredPopup--noFullScreenOnPhone" : "neo__viewContainer__centeredPopup--fullScreenOnPhone",
    withDarkBackdrop ? "neo__viewContainer__centeredPopup--withDarkBackdrop" : "",
    fullScreen ? "neo__viewContainer__centeredPopup--fullScreen" : "",
    className || ""
  ].join(" ")

  return <div className={_className}>
    {children}
  </div>
}
