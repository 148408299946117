import React, { createContext, useContext } from "react"
import _ from "lodash"
import axios from "axios"
import { Signal } from "signals"

interface IApiContext {
  send(action: string, payload?: any): void,
  on(fn: (payload: any) => void): void
  off(fn: (payload: any) => void): void
}

export interface ScopedApi<Req extends Base.Api.Request, Resp extends Base.Api.Response> {
  on(fn: (resp: Resp) => void): (resp: Resp) => void
  off(fn: (resp: Resp) => void): void
  send(message: Req): void
}

export interface IApi<Req, Resp> {
  on(fn: (resp: Resp) => void): (resp: Resp) => void
  off(fn: (resp: Resp) => void): void
  send(message: Req): void
}

export const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-User-Agent": "mobile-teaser"
  },
  withCredentials: true
}

const ApiContext = createContext<IApiContext>({ send: () => {}, on: () => {}, off: () => {}, })
ApiContext.displayName = "ApiContext"

export const WithApi: React.FC<{apiHost: string}> = ({ apiHost, children }) => {
  const signal = new Signal()

  const send = (action: string, payload: any) => {
    axios.post(
      `${apiHost}/${action}`,
      { json: JSON.stringify(payload) },
      axiosConfig
      ).then((resp) => {
        const responses = _.isArray(resp.data) ? resp.data : [resp.data]
        _.each(responses, (r) => {
          signal.dispatch(r)
          console.log((r as any).action, r)
        })
      }).catch((err: Error) => console.warn(err.message))
  }
  const on = (fn: (payload: any) => void) => {
    signal.add(fn)
    return fn
  }
  const off = (fn: (payload: any) => void) => {
    signal.remove(fn)
  }

  return <ApiContext.Provider value={{send, on, off}}>{children}</ApiContext.Provider>
}

export function useApi<Req extends Base.Api.Request, Resp extends Base.Api.Response>(): ScopedApi<Req, Resp> {
  const api = useContext(ApiContext)
  return {
    on(fn: (resp: Resp) => void): (resp: Resp) => void {
      return api.on(fn as any) as any
    },
    off(fn: (resp: Resp) => void): void {
      api.off(fn as any)
    },
    send(message: Req): void {
      const msgWithoutAction = _.clone(message)
      delete (msgWithoutAction as any).action
      api.send(message.action, msgWithoutAction as any)
    }
  }
}
