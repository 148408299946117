import React, { useEffect, useState } from "react"
import I18n from "i18n-js"
import _ from "lodash"
import useForceUpdateEveryMillis from "@helper/useForceUpdateEveryMillis"
import secondsLeft from "@helper/secondsLeft"
import prettyCountdownSeconds from "@helper/prettyCountdownSeconds"
import AmountBubble from "@ui/AmountBubble"
import { IconLocked } from "@ui/Icon"
import Sticker from "@ui/Sticker"
import { useStickerConfig } from "@context/StickerConfig"
import Button from "@ui/Button"
import { SHOW_RECEIVED_PACKS_DONE } from "@store/packs/actions"
import Pack from "@ui/Pack"

export const ItemTitle: React.FC<{black?: boolean}> = ({ children, black }) => {
  const className = [
    "neo__kioskItem__title",
    black ? "neo__kioskItem__title--black" : ""
  ].join(" ")
  return <div className={className}>
    <span>{children}</span>
  </div>
}

type IKioskItemType = "album" | "scan" | "packs" | "stickers" | "enterCode"

export const Item: React.FC<{variant: IKioskItemType, onClick: () => void}> = ({ variant, children, onClick }) => {
  return <div className={`neo__kioskItems__itemHolder neo__kioskItems__itemHolder--${variant}`}>
    <div onClick={onClick} className={`neo__kioskItem neo__kioskItem--${variant}`}>
      {children}
    </div>
  </div>
}

export const ItemAlbum: React.FC<{onClick(): void}> = ({ onClick }) => {
  return <Item variant="album" onClick={() => onClick()}>
    <div className="neo__titlePositioner"><ItemTitle>{I18n.t("welcome.btn_album")}</ItemTitle></div>
    <div className="neo__albumPositioner">
      <div className="neo__album" />
    </div>
    <div className="neo__lockPositioner">
      <IconLocked />
    </div>
  </Item>
}

export const ItemScan: React.FC<{onClick(): void}> = ({ onClick }) => {
  return <Item variant="scan" onClick={() => onClick()}>
    <div className="neo__titlePositioner"><ItemTitle>{I18n.t("welcome.btn_scan")}</ItemTitle></div>
    <div className="neo__contentPositioner">
      <div className="neo__lockPositioner"><IconLocked /></div>
      <div className="neo__scan__wrapperWithOpacity">
        <div className="neo__bottlePositioner"><div className="neo__scan__bottle" /></div>
      </div>
    </div>
  </Item>
}

export const ItemStickers: React.FC<{onClick(): void, stickers: number[]}> = ({ onClick, stickers }) => {
  const { getSticker } = useStickerConfig()

  const stackClassName = [
    "neo__kioskItems__stickersStack",
    stickers.length > 0 ? "neo__kioskItems__stickersStack--withStickers" : "neo__kioskItems__stickersStack--empty"
  ].join(" ")
  return <Item variant="stickers" onClick={() => onClick()}>
    <div className="neo__titlePositioner"><ItemTitle>{I18n.t("welcome.btn_stacks")}</ItemTitle></div>
    <div className="neo__contentPositioner">
      <div className="neo__amountPositioner"><AmountBubble amount={stickers.length} /></div>
      <div className="neo__stickersBackground" />
      <div className="neo__stickersStackPositioner">
        <div className={stackClassName}>
          {_.chain(stickers).slice(0, 5).reverse().map((sId, index) => {
            const sticker = getSticker(sId)
            return sticker
              ? <div key={index}><Sticker tiltABit rotation="forcePortrait" sticker={sticker} size={100} /></div>
              : null
          }).value()}
        </div>
      </div>
    </div>
  </Item>
}

const RECEIVED_PACKS_ANI = {
  DELAY_PER_PACK_MILLIS: 300,
  ANI_DURATION_MILLIS: 1000,
  COME_BACK_DURATION_MILLIS: 300,
  FAN_DEG: 120
}
const ShowReceivedPacks: React.FC<{amount: number, onDone(): void}> = ({ amount, onDone }) => {
  const [ clearRotationAndComeBack, setClearRotationAndComeBack ] = useState(false)

  const anglePerPack = RECEIVED_PACKS_ANI.FAN_DEG / (amount + 1)
  const angleOffset = -(RECEIVED_PACKS_ANI.FAN_DEG / 2) + anglePerPack

  useEffect(() => {
    let timeout = window.setTimeout(() => {
      setClearRotationAndComeBack(true)
      timeout = window.setTimeout(() => {
        onDone()
      }, RECEIVED_PACKS_ANI.COME_BACK_DURATION_MILLIS)
    }, amount * RECEIVED_PACKS_ANI.DELAY_PER_PACK_MILLIS + RECEIVED_PACKS_ANI.ANI_DURATION_MILLIS)

    return () => { // cleanup
      window.clearTimeout(timeout)
    }
  }, [])

  return <div className="neo__kioskItems__openPacks__showReceivedFreePacks">
    {_.times(amount, (num) => {
      const angle = clearRotationAndComeBack
        ? 0
        : angleOffset + (num * anglePerPack) + 3
      return <div
        key={num}
        className="neo__kioskItems__openPacks__showReceivedFreePacks__packAngleHolder"
        style={{ transform: `rotate(${angle}deg)` }}>
        <div
          className={`neo__kioskItems__openPacks__showReceivedFreePacks__packAniHolder ${clearRotationAndComeBack ? "--comeBack" : ""}`}
          style={{ animationDelay: `${clearRotationAndComeBack ? 0 : RECEIVED_PACKS_ANI.DELAY_PER_PACK_MILLIS * num}ms` }}>
          <Pack size={100} shine={{durationMillis: 1500, delayMillis: RECEIVED_PACKS_ANI.DELAY_PER_PACK_MILLIS * num + 200}}/>
        </div>
      </div>
    })}
  </div>
}

const noop = () => {}

export const ItemEnterCode: React.FC<{onClick(): void}> = ({ onClick }) => {
  return <Item variant="enterCode" onClick={() => onClick()}>
    <div className="neo__titlePositioner"><ItemTitle>{I18n.t("desk.packs.title_code")}</ItemTitle></div>
    <div className="neo__packBoxPositioner"><div className="neo__kioskItems__packBox" /></div>
  </Item>
}

interface ItemOpenPacksProps {
  numPacks: number
  state:
    {
      type: "normal"
      openPack(): void
      nextFreePackInSeconds?: number
      receivedNextFreePackInfoAt?: Date
    } | {
      type: "freePacksAvailable"
      getFreePacks(): void
    } | {
      type: "showReceivedFreePacks",
      amount: number,
      onShowingDone(): void
    }
}

export const ItemOpenPacks: React.FC<ItemOpenPacksProps> = ({ numPacks, state }) => {
  useForceUpdateEveryMillis(1000) // for countdown

  // don't show newly received packs amount when received-free-packs ani playing
  const numPacksToShowOnAmountBubble = Math.max(0, state.type === "showReceivedFreePacks"
    ? numPacks - state.amount
    : numPacks)
  const numPacksToShowOnStack = numPacksToShowOnAmountBubble > 5 ? 5 : numPacksToShowOnAmountBubble

  const onClick = state.type === "freePacksAvailable"
    ? state.getFreePacks
    : (state.type === "showReceivedFreePacks"
      ? noop
      : state.openPack)
  return (
    <Item variant="packs" onClick={() => onClick()}>
      <div className="neo__titlePositioner"><ItemTitle>{I18n.t("desk.packs.title")}</ItemTitle></div>
      <div className="neo__contentPositioner">
        <div className="neo__amountPositioner"><AmountBubble amount={numPacksToShowOnAmountBubble} /></div>
        <div className="neo__packsPositioner">
          {numPacksToShowOnStack > 0 &&
            <div className={`neo__kioskItems__openPacks__packs neo__kioskItems__openPacks__packs--${numPacksToShowOnStack}`}>
              {_.times(numPacksToShowOnStack, (num) => {
                return <div key={num} className="neo__kioskItems__openPacks__packs__pack" />
              })}
            </div>}
        </div>
        <div className="neo__noPacksPositioner">
          {numPacksToShowOnStack === 0 &&
            <div className="neo__kioskItems__openPacks__noPacks" />}
        </div>
        {state.type === "freePacksAvailable" &&
          <div className="neo__kioskItems__openPacks__getFreePacksPositioner">
            <div className="neo__kioskItems__openPacks__getFreePacks">
              <Button type="primaryOnLight" onClick={() => {/* already handled through parent-item-click */}}>{I18n.t("daily_packs.receive_btn_one_pack")}</Button>
            </div>
          </div>}
        {state.type === "normal" && _.isNumber(state.nextFreePackInSeconds) && !!state.receivedNextFreePackInfoAt &&
          <div className="neo__kioskItems__openPacks__countdownPositioner">
            <div className="neo__kioskItems__openPacks__countdown">
              <span>{I18n.t("daily_packs.countdown_info")}</span>
              <strong>{prettyCountdownSeconds(secondsLeft(state.nextFreePackInSeconds, state.receivedNextFreePackInfoAt))}</strong>
            </div>
          </div>}
        {state.type === "showReceivedFreePacks" &&
          <div className="neo__kioskItems__openPacks__showReceivedFreePacksPositioner">
            <ShowReceivedPacks amount={state.amount} onDone={state.onShowingDone} />
          </div>}
      </div>
    </Item>
  )
}
