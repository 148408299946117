import React, { createContext, useContext, useEffect, useState } from "react"
import axios from "axios"
import I18n from "i18n-js"
import _ from "lodash"

import "./LoadConfigs.scss"

import { WithAssetPath } from "./AssetPath"
import { WithStickerConfig } from "./StickerConfig"
import { axiosConfig } from "@api"
import LoadingSpinner from "@ui/LoadingSpinner"
import Button from "@ui/Button"
import todoBabelKeys from "./todoBabelKeys"

interface LoadConfigsProps {
  assetHost: string
  locale: string
  WrapperWhileLoading?: React.FC
}

const DummyWrapperWhileLoading: React.FC = ({ children }) => <div>{children}</div>

export const LoadConfigs: React.FC<LoadConfigsProps> = ({ assetHost, locale, children, WrapperWhileLoading }) => {
  if (!WrapperWhileLoading) { WrapperWhileLoading = DummyWrapperWhileLoading }

  const [ manifest, setManifest ] = useState<any>()
  const [ babel, setBabel ] = useState<any>()
  const [ config, setConfig ] = useState<any>()
  const [ error, setError ] = useState<string>()

  useEffect(() => { // load asset manifest file
    let isActive = true
    axios.get("/manifest_update.json", axiosConfig).then((response) => {
      if (isActive) { setManifest(response.data) }
    }).catch((err: Error) => {
      console.warn(err)
      if (isActive) { setError(err.message) }
    })

    return () => { // cleanup
      isActive = false
    }
  }, [])

  useEffect(() => { // load sticker config and babel (after manifest)
    let isActive = true

    if (manifest) {
      const babelPath = manifest[`babel/${locale}.json`]
      const configPath = manifest["config/config.json"]

      axios.get(`/assets/${configPath}`, axiosConfig).then((response) => {
        if (isActive) { setConfig(response.data) }
      }).catch((err: Error) => {
        console.warn(err)
        if (isActive) { setError(err.message) }
      })

      axios.get(`/assets/${babelPath}`, axiosConfig).then((response) => {
        console.log(response.data)
        I18n.locale = locale
        I18n.defaultLocale = locale
        I18n.translations = _.merge(response.data, todoBabelKeys(locale))
        setBabel(response.data)
      }).catch((err: Error) => {
        console.warn(err)
        if (isActive) { setError(err.message) }
      })
    }

    return () => { // cleanup
      isActive = false
    }
  }, [!!manifest])

  if (error) {
    return <WrapperWhileLoading>
      <div className="neo__loadConfigs__error">
        <p className="neo__loadConfigs__errorMsg">{error}</p>
        <p>
          <Button onClick={() => window.location.reload()} type="primaryOnLight">Retry</Button>{/* maybe no babel at this time */}
        </p>
      </div>
    </WrapperWhileLoading>
  } else if (manifest && config && babel) {
    return (
      <WithAssetPath assetHost={assetHost} manifest={manifest}>
        <WithStickerConfig groups={config.groups} stickers={config.stickers}>
          {children}
        </WithStickerConfig>
      </WithAssetPath>
    )
  } else {
    return <WrapperWhileLoading><LoadingSpinner type="onDark" /></WrapperWhileLoading>
  }
}

export default LoadConfigs
