import React, { useState } from "react"
import _ from "lodash"

import { EnterCodeInner } from "./"

import { PageTitle, SectionTitle, Block, BlockTitle, Note, Scenarios, IScenario } from "@sandbox/Sandbox"
import Kiosk from "@ui/Kiosk"
import { useStickerConfig } from "@context/StickerConfig"
import { EnterCodeViewElements, generateViewElements } from "./useEnterCodeView"

type GenViewConfFn = (setScenarioIndex: (i: number) => void, someStickers: (amount: number) => number[]) => EnterCodeViewElements

const ENTER_CODE_VIEW_CONFS: {title: string, genViewConf: GenViewConfFn}[]  = [
  {
    title: "Enter",
    genViewConf: (setScenarioIndex, someStickers) => {
      return generateViewElements({type: "enter", submitCode: (c: string) => alert(c)})
    }
  },
  {
    title: "Enter with Error",
    genViewConf: (setScenarioIndex, someStickers) => {
      return generateViewElements({type: "enter", error: "There was an error!", submitCode: (c: string) => alert(c)})
    }
  },
  {
    title: "Received 1 pack",
    genViewConf: (setScenarioIndex, someStickers) => {
      return generateViewElements({type: "received", numPacks: 1, stickers: [], enterAnother: () => setScenarioIndex(0)})
    }
  },
  {
    title: "Received 3 packs",
    genViewConf: (setScenarioIndex, someStickers) => {
      return generateViewElements({type: "received", numPacks: 3, stickers: [], enterAnother: () => setScenarioIndex(0)})
    }
  },
  {
    title: "Received 5 stickers",
    genViewConf: (setScenarioIndex, someStickers) => {
      return generateViewElements({type: "received", numPacks: 0, stickers: someStickers(5), enterAnother: () => setScenarioIndex(0)})
    }
  },
  {
    title: "Received 1 pack + 5 stickers",
    genViewConf: (setScenarioIndex, someStickers) => {
      return generateViewElements({type: "received", numPacks: 1, stickers: someStickers(5), enterAnother: () => setScenarioIndex(0)})
    }
  },
  {
    title: "Received 30 stickers",
    genViewConf: (setScenarioIndex, someStickers) => {
      return generateViewElements({type: "received", numPacks: 0, stickers: someStickers(30), enterAnother: () => setScenarioIndex(0)})
    }
  }
] as const

export const useEnterCodeScenarios = (map: (conf: {title: string, genView: (setScenarioIndex: (i: number) => void) => EnterCodeViewElements}) => IScenario) => {
  const { stickers } = useStickerConfig()
  const someStickers = (amount: number) => _.chain(stickers).sampleSize(amount).map((s) => s.id).value()

  return _.map(_.map(ENTER_CODE_VIEW_CONFS, (conf) => {
    return {
      title: conf.title,
      genView: (setScenarioIndex: (i: number) => void) => conf.genViewConf(setScenarioIndex, someStickers)
    }
  }), map)
}

const Sandbox: React.FC = () => {

  const scenarios = useEnterCodeScenarios((conf) => {
    return {
      title: conf.title,
      el: (setScenarioIndex) => <EnterCodeInner view={conf.genView(setScenarioIndex)} onClose={() => setScenarioIndex(undefined)} />
    }
  })

  return <>
    <PageTitle>Enter Code</PageTitle>

    <Scenarios scenarios={scenarios} WrapWith={({children}) => <Kiosk>{children}</Kiosk>}/>
  </>
}

export default Sandbox
