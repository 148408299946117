import React, { useEffect } from "react"
import _ from "lodash"

const useOnWindowResize = (fn: () => void) => {
  useEffect(() => {
    const listener = _.throttle(fn, 300)
    window.addEventListener("resize", listener)

    return () => { // cleanup
      window.removeEventListener("resize", listener)
    }
  })
}

export default useOnWindowResize
