import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import I18n from "i18n-js"

import "./KioskItems.scss"
import { Item, ItemOpenPacks, ItemTitle, ItemAlbum, ItemScan, ItemStickers, ItemEnterCode } from "./ui"

import { IAppState, IPacksInfo, IShowReceivedPacks } from "@store/appState"
import LoadingSpinner from "@ui/LoadingSpinner"
import NotForGuestsPopup from "@ui/NotForGuestsPopup"
import DownloadAppPopup from "@ui/DownloadAppPopup"
import NoMorePacksPopup from "@ui/NoMorePacksPopup"
import { CenteredPopup } from "@ui/ViewContainer"
import { requestReceiveDailyPacksAction, showReceivedPacksDoneAction } from "@store/packs/actions"
import BottomBar from "@ui/BottomBar"
import ConnectErrorPopup from "@ui/ConnectErrorPopup"
import { connectErrorShownAction } from "@store/misc"

interface KioskItemsProps {
  stickers: Base.Api.CardStack
  packs: IPacksInfo
  isGuest: boolean
  connectError?: string
  showReceivedPacks?: IShowReceivedPacks
  onShowingReceivedPacksDone?(): void
}

export const KioskItemsInner: React.FC<KioskItemsProps> = ({stickers, packs, isGuest, showReceivedPacks, connectError, onShowingReceivedPacksDone}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [ showConnectErrorPopup, setShowConnectErrorPopup ] = useState(!!connectError)
  const [ showGuestRestrictionPopup, setShowGuestRestrictionPopup ] = useState(false)
  const [ showNoMorePacksPopup, setShowNoMorePacksPopup ] = useState(false)
  const [ showDownloadAppPopup, setShowDownloadAppPopup ] = useState(false)

  return (
    <div className="neo__kioskItems">
      {showConnectErrorPopup && <ConnectErrorPopup closePopup={() => setShowConnectErrorPopup(false)} msg={connectError || ""} />}
      {showGuestRestrictionPopup && <NotForGuestsPopup closePopup={() => setShowGuestRestrictionPopup(false)} />}
      {showDownloadAppPopup && <DownloadAppPopup closePopup={() => setShowDownloadAppPopup(false)} />}
      {showNoMorePacksPopup && <NoMorePacksPopup closePopup={() => setShowNoMorePacksPopup(false)}/>}
      <div className="neo__kioskItems__itemsHolder">
        <ItemScan onClick={() => setShowDownloadAppPopup(true)} />
        <ItemStickers stickers={stickers} onClick={() => history.push("/stickers")} />
        <ItemAlbum onClick={() => setShowDownloadAppPopup(true)} />
        <ItemEnterCode onClick={() => isGuest ? setShowGuestRestrictionPopup(true) : history.push("/enter_code")} />
        <ItemOpenPacks
          numPacks={packs.numUnopened}
          state={
            showReceivedPacks
              ? {
                  type: "showReceivedFreePacks",
                  amount: showReceivedPacks.amount,
                  onShowingDone: () => onShowingReceivedPacksDone ? onShowingReceivedPacksDone() : dispatch(showReceivedPacksDoneAction())
                }
              : (packs.hasNewPacksWaiting
                ? {
                    type: "freePacksAvailable",
                    getFreePacks: () => dispatch(requestReceiveDailyPacksAction())
                  }
                : {
                    type: "normal",
                    openPack: packs.numUnopened > 0
                      ? () => history.push("/open_pack")
                      : () => setShowNoMorePacksPopup(true),
                    nextFreePackInSeconds: packs.nextFreePackInSeconds,
                    receivedNextFreePackInfoAt: packs.receivedNextFreePackInfoAt
                  })
          }
      />
      </div>
      <BottomBar onClick={() => setShowDownloadAppPopup(true)} />
    </div>
  )
}

const KioskItems: React.FC = () => {
  const dispatch = useDispatch()
  const selected = useSelector<IAppState, KioskItemsProps & { initDone: boolean, receivedUser: boolean }>((state) => {
    return {
      connectError: state.connectError,
      initDone: state.initDone,
      stickers: state.tempStack,
      packs: state.packs,
      isGuest: !!state.ownUser?.guest,
      receivedUser: !!state.ownUser,
      showReceivedPacks: state.showReceivedPacks
    }
  })

  useEffect(() => {
    return () => { // mark connectError as seen when unmount
      if (selected.connectError) { dispatch(connectErrorShownAction()) }
    }
  }, [selected.connectError])

  if (!!selected.receivedUser && selected.initDone) {
    return <KioskItemsInner {...selected} />
  } else {
    return <CenteredPopup><LoadingSpinner type="onDark" /></CenteredPopup>
  }
}

export default KioskItems
