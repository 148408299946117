import { IApi, useApi } from "@api"
import _ from "lodash"
import { useEffect } from "react"
import { useDispatch, useStore } from "react-redux"

import { IAppState } from "./appState"

export const OWN_USER_RECEIVED = "OWN_USER_RECEIVED"

interface ReceivedOwnUserAction {
  type: typeof OWN_USER_RECEIVED
  user: Base.Api.User
}

export type ActionTypes = ReceivedOwnUserAction

export const ownUserReducer = (
  state: IAppState,
  action: ActionTypes
): IAppState => {
  switch (action.type) {
    case OWN_USER_RECEIVED:
      return {
        ...state,
        ownUser: action.user
      }
  }
  return state
}

export const receivedOwnUserAction = (resp: OwnUserInfo.Api.Response): ReceivedOwnUserAction => {
  return {
    type: OWN_USER_RECEIVED,
    user: resp.user_info
  }
}

export const useOwnUser = () => {
  const api = useApi<any, OwnUserInfo.Api.Response>()
  const dispatch = useDispatch()

  useEffect(() => {
    const listener = api.on((msg) => {
      if (msg.action === "own_user_info") {
        dispatch(receivedOwnUserAction(msg))
      }
    })

    return () => { // cleanup
      api.off(listener)
    }
  }, [])
}
