import I18n from "i18n-js"
import _ from "lodash"

const getErrorMessage = (error?: Base.Api.ErrorValue) => {
  if (_.isString(error)) {
    return error
  } else if (error && error.message) {
    return I18n.t(`errors.${error.message}`, {...error.args, defaultValue: error.message})
  } else {
    return ""
  }
}

export default getErrorMessage
