import React from "react"

import "./ViewTitle.scss"

const ViewTitle: React.FC<{type: "onLight" | "onDark"}> = ({children, type}) => {
  return <div className="neo__viewTitle__holder">
    <h1 className={`neo__viewTitle neo__viewTitle--${type}`}>{children}</h1>
  </div>
}

export default ViewTitle
