import { IApi, useApi } from "@api"
import _ from "lodash"
import { useEffect } from "react"
import { useDispatch, useStore } from "react-redux"

import { AppThunk } from "./"
import { IAppState } from "./appState"

export const CONNECT_ERROR_SHOWN = "CONNECT_ERROR_SHOWN"

interface ConnectErrorShownAction {
  type: typeof CONNECT_ERROR_SHOWN
}

export type ActionTypes = ConnectErrorShownAction

export const miscReducers = (
  state: IAppState,
  action: ActionTypes
): IAppState => {
  switch (action.type) {
    case CONNECT_ERROR_SHOWN:
      return {
        ...state,
        connectError: undefined
      }
  }
  return state
}

export const connectErrorShownAction = (): ConnectErrorShownAction => {
  return {
    type: CONNECT_ERROR_SHOWN
  }
}
