import React from "react"

import CloseIcon from "./"

import { PageTitle, SectionTitle, Block, BlockTitle } from "@sandbox/Sandbox"

const Sandbox: React.FC = () => {
  return <>
    <SectionTitle>CloseIcon</SectionTitle>
    <Block centered>
      <CloseIcon />
    </Block>
  </>
}

export default ["Ui", Sandbox]
