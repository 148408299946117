import { IAppState } from "@store/appState"
import NotForGuestsPopup from "@ui/NotForGuestsPopup"
import StickerPopup from "@ui/StickerPopup"
import EnterCode from "@view/EnterCode"
import useEnterCodeView, { EnterCodeViewElements, STICKER_SIZE_TABLET } from "@view/EnterCode/useEnterCodeView"
import React from "react"
import { useSelector } from "react-redux"
import "./OnlyEnterCode.scss"
import ViewTitle from "@ui/ViewTitle"
import { Row } from "@ui/Layout"

export const WrapperWhileLoadingConfigsOnlyEnterCode: React.FC = ({children}) => {
  return <div className="neo__OEC__wrapperWhileLoadingConfigs">
    {children}
  </div>
}

export const KioskWrapperWhenOnlyEnterCode: React.FC = ({children}) => {
  return <div className="neo__OEC__kioskWrapper">
    {children}
  </div>
}

export const NotForGuests: React.FC = () => {
  return <>
    <NotForGuestsPopup noFullScreenOnPhone/>
  </>
}

export const OnlyEnterCodeInner: React.FC<{view: EnterCodeViewElements}> = ({view}) => {
  const bodyClassName = [
    "neo__OEC__body",
    `neo__OEC__body--${view.state.type}` // "--enter" or "--received"
  ].join(" ")

  return <div className="">
    {view.stickerForPopup &&
      <StickerPopup sticker={view.stickerForPopup} closePopup={view.clearStickerForPopup} fullScreen />}
    {view.Title &&
      <Row>
        <ViewTitle type="onDark">{view.Title}</ViewTitle>
      </Row>}
    <Row>
      <div className={bodyClassName}>
        {view.Body}
      </div>
    </Row>
    {view.Foot &&
      <Row>
        {view.Foot}
      </Row>}
  </div>
}

const OnlyEnterCode: React.FC = () => {  
  const view = useEnterCodeView({stickerSizePhone: STICKER_SIZE_TABLET}) // larger stickers on phone, because lots of space to scroll
  const {isGuest} = useSelector<IAppState, {isGuest: boolean}>((st) => { return {isGuest: !!(st.ownUser?.guest)}})

  return isGuest
    ? <NotForGuests />
    : <OnlyEnterCodeInner view={view} />
}

export default OnlyEnterCode
