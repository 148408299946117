import React, { useEffect, useState } from "react"

const useWaitAtLeastMillis = (millis: number) => {
  const [ done, setDone ] = useState(millis > 0 ? false : true)

  useEffect(() => {
    let timeout: number
    if (!done) {
      timeout = window.setTimeout(() => setDone(true), millis)
    }

    return () => { // cleanup
      window.clearTimeout(timeout)
    }
  }, [done])

  return done
}

export default useWaitAtLeastMillis
