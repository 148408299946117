import React from "react"
import { useHistory } from "react-router-dom"

import "./Button.scss"

interface ButtonProps {
  type: "primaryOnDark" | "primaryOnLight" | "secondaryOnDark" | "secondaryOnLight"
  onClick?(): void
  to?: string
  href?: string
  disabled?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, onClick, to, href, disabled, type }) => {
  const history = useHistory()

  if (!!to) {
    href = history.createHref({ pathname: to })
  }

  const className = [
    "neo__button",
    `neo__button--${type}`
  ].join(" ")

  // IMPORTANT: Same DOM structure is also used in Rails partials!
  return disabled
    ? <span className={className}><span className="neo__button__inner">{children}</span></span>
    : <a className={className} onClick={onClick} href={href}><span className="neo__button__inner">{children}</span></a>
}

export default Button
