import React from "react"

import "./AmountBubble.scss"

const AmountBubble: React.FC<{amount: number}> = ({ amount }) => {
  const className = [
    "neo__amountBubble",
    `${amount}`.length >= 3 ? "neo__amountBubble--smallerText" : ""
  ].join(" ")

  return <div className={className}><span>{amount}</span></div>
}

export default AmountBubble
