import React, { createContext, useContext } from "react"

interface IAppLinksContext {
  ios?: string
  android?: string
}

const AppLinksContext = createContext<IAppLinksContext>({})
AppLinksContext.displayName = "AppLinksContext"

export const WithAppLinks: React.FC<IAppLinksContext> = ({ ios, android, children }) => {
  return <AppLinksContext.Provider value={{ ios: ios && ios.length > 0 ? ios : undefined, android: android && android.length > 0 ? android : undefined }}>
    {children}
  </AppLinksContext.Provider>
}

export const useAppLinks = () => useContext(AppLinksContext)
