import React from "react"
import I18n from "i18n-js"
import { Link } from "react-router-dom"

import "./CloseIcon.scss"

const CloseIcon: React.FC<{className?: string, onClick?(): void, to?: string, positionTopRight?: boolean}> = ({ onClick, className, positionTopRight, to }) => {
  const _className = [
    "neo__closeIcon",
    positionTopRight ? "neo__closeIcon--positionTopRight" : "",
    className || ""
  ].join(" ")

  return !!to
    ? <Link className={_className} to={to}>{I18n.t("general.back")}</Link>
    : <a className={_className} onClick={(evt) => {evt.preventDefault(); onClick?.()}}>{I18n.t("general.back")}</a>
}

export default CloseIcon
