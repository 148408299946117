const todoBabelKeys = (locale: string) => {
  const tr: {[key: string]: any} = {}
  tr[locale] = {
    // teaser: {
    //   guests_cant_unlock_codes: "Complete your account to enter promo codes and unlock new packs!",
    //   apps_title: "Download the App to enjoy the full experience!",
    //   no_stickers_open_some_packs: "You don't have any stickers yet. Open sticker packs to get some.",
    // }
  }
  return tr
}

export default todoBabelKeys
