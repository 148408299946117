import React from "react"
import { useMediaQuery } from "react-responsive"
import cssVar from "@styles/config.scss"

const useDevice = () => {
  const isTablet = useMediaQuery({
    query: `(min-width: ${cssVar.tabletUp})`
  })
  return {
    isPhone: !isTablet,
    isTablet
  }
}

export default useDevice
